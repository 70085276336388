export const UPLOAD_STATUS = {
  DONE: 'done',
  REMOVED: 'removed',
};
export const CORPORATE_ALGORITHMS = [
  {
    label: 'VRP',
    value: 'vrp',
  },
  {
    label: 'Sergio',
    value: 'sergio',
  },
];
