import { useQuery, useMutation } from 'react-query';
import { API } from '@utils/api-config';
import camelCase from 'camelcase-keys';
import { useCustomers } from '@context/customers';
import { DIRECTION } from './constants';
import { showAlert } from '@swvl/alert';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '@utils/index';

// Types
type PendingShift = {
  _id: string;
  is_active: boolean;
  weekdays: number[];
  corporate: string;
  office_location: { _id: string; name: string; station?: string };
  start_date: string;
  end_date: string;
  deadline: {
    type: string;
    value: number;
  };
  deadline_date_time: string;
  direction: DIRECTION;
  time: number;
  createdAt: string;
  updatedAt: string;
  assigned_employees: number;
  completed: boolean;
};

export type PendingShiftsAPIResponse = {
  type: string;
  hits: PendingShift[];
  total: number;
};

type ShiftRunStatus = 'INPROGRESS' | 'FAILED' | 'COMPLETED';

type ShiftRun = {
  run_id: number;
  shift_id: string;
  shift_time: number;
  shift_direction: DIRECTION;
  assigned_employees: number;
};

export type InprogressShiftRun = ShiftRun & {
  initiated_at: string;
};

export type FailedShiftRun = ShiftRun & {
  failed_at: string;
  failure_reason: string;
};

export type CompletedShiftRun = ShiftRun & {
  excluded_employees: number;
  recommended_rides: number;
  dispatched_rides: number;
  average_utilization: number;
};

export type ShiftRunsAPIResponse<T extends ShiftRunStatus> = {
  hits: (T extends 'INPROGRESS' ? InprogressShiftRun : T extends 'FAILED' ? FailedShiftRun : CompletedShiftRun)[];
  total: number;
};

export type PendingShifts = ReturnType<typeof pendingShiftsDataTransformer>;

// Transforms
const pendingShiftsDataTransformer = (data: PendingShiftsAPIResponse) => camelCase(data, { deep: true });

const shiftsRunsDataTransformer = <T extends ShiftRunStatus>(data: ShiftRunsAPIResponse<T>) => {
  return camelCase(data, { deep: true });
};

// Resources
const fetchPendingShifts = async ({
  corporateId,
  page,
  limit,
  date,
  direction,
}: {
  corporateId: string;
  page: number;
  limit: number;
  date?: Date;
  direction?: DIRECTION;
}) => {
  const params = {
    page,
    limit,
    include_deadlines: true,
    type: 'day-based',
    date,
    direction,
  };
  const { data } = await API.get<PendingShiftsAPIResponse>(
    `/corporate/${corporateId}/network-generator/pending-reservations`,
    {
      params,
    },
  );

  return pendingShiftsDataTransformer(data);
};

// Resources
const fetchShiftRuns = async <T extends ShiftRunStatus>({
  corporateId,
  page,
  limit,
  date,
  direction,
  status,
}: {
  corporateId: string;
  page: number;
  limit: number;
  date?: Date;
  direction?: DIRECTION;
  status: T;
}) => {
  const params = {
    page,
    limit,
    date,
    direction,
    status,
  };
  const { data } = await API.get<ShiftRunsAPIResponse<T>>(`/corporate/${corporateId}/network-generator/shift-runs`, {
    params,
  });

  return shiftsRunsDataTransformer<T>(data);
};

const syncShiftRuns = async ({ corporateId, runId }: { corporateId: string; runId: number }) => {
  const { data } = await API.patch(`/corporate/${corporateId}/network-generator/shift-runs/${runId}/sync`);
  return shiftsRunsDataTransformer(data);
};

// Hooks
export const usePendingShifts = ({
  page = 1,
  limit = 10,
  date,
  direction,
}: {
  page?: number;
  limit?: number;
  date?: Date;
  direction?: DIRECTION;
}) => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['shifts', 'pending', selectedCorporateId, [page, limit, date, direction]],
    () => fetchPendingShifts({ corporateId, page, limit, date, direction }),
    {
      enabled: !!corporateId,
    },
  );
};

export const useShiftRuns = <T extends ShiftRunStatus>({
  page = 1,
  limit = 10,
  date,
  direction,
  status,
}: {
  page?: number;
  limit?: number;
  date?: Date;
  direction?: DIRECTION;
  status: T;
}) => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['shifts', 'runs', selectedCorporateId, status, [page, limit, date, direction]],
    () => fetchShiftRuns<T>({ corporateId, page, limit, date, direction, status }),
    {
      enabled: !!corporateId,
    },
  );
};

export const useSyncShiftRuns = () => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';
  return useMutation((runId: number) => syncShiftRuns({ corporateId, runId }), {
    mutationKey: 'shifts/sync-run',
    onSuccess: () => {
      showAlert({
        id: 'sync-shift-run-id',
        message: 'Run sync successful',
        type: 'success',
        mode: 'dark',
      });
    },
    onError: (error: AxiosResponse) => {
      const message = getErrorMessage(error);
      showAlert({
        id: 'sync-shift-run-id',
        message,
        type: 'error',
        mode: 'dark',
      });
    },
  });
};
