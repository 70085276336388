import { useEffect } from 'react';
import Image from 'next/image';
import TagManager from 'react-gtm-module';
import { Button } from '@swvl/button';
import generateRidesBg from 'public/images/generate-rides-bg.png';

export const Onboarding = ({ disableOnboarding }: { disableOnboarding: () => void }) => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'screen_generate_rides_info',
      },
    });
  }, []);

  return (
    <div>
      <Image src={generateRidesBg} alt="generate-rides" />
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
          px: 'spacing-xl',
          mt: 'spacing-xxxl',
        }}
      >
        <h3 sx={{ variant: 'text.h4', m: '0', mb: 'spacing-m' }}>Generate rides</h3>
        <p sx={{ variant: 'text.p-small', m: '0', mb: '80px' }}>
          Initiate route generation for employee bookings and recommend the rides to cater to the demand.
        </p>
        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            disableOnboarding();
            TagManager.dataLayer({
              dataLayer: {
                event: 'action_click_get_started',
              },
            });
          }}
        >
          Get started
        </Button>
      </div>
    </div>
  );
};
