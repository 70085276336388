import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Spinner } from 'theme-ui';

import Drawer from '@swvl/drawer';
import { Button, IconButton } from '@swvl/button';
import { CloseIcon, GenerateRidesIcon } from '@swvl/icons';
import { useTheme } from '@swvl/theme';
import { RadioButtonGroup, RadioButton } from '@swvl/radio-button';
import { useLocalStorage } from '@hooks/use-local-storage';
import { GenerateRidesData } from '@customers/generate-rides/types';
import { useCorporate } from '@customers/corporate-configuration/resources';

import { Onboarding } from './onboarding';
import { GenerateRidesForm } from './generate-rides-form';
import { MissingDataStep } from './missing-data-step';
import { useUnmappedEmployees, useShiftDetails, useGenerateRides } from './resources';

type GenerateRidesDrawerProps = {
  isDrawerOpen: boolean;
  closeDrawer: () => void;
  generateRidesData?: GenerateRidesData;
};

export const GenerateRidesDrawer = ({ isDrawerOpen, closeDrawer, generateRidesData }: GenerateRidesDrawerProps) => {
  const { theme } = useTheme();
  const [routingMethod, setRoutingMethod] = useState<'algorithmic' | 'custom'>('algorithmic');
  const [algorithmicRoutingConfirmed, setAlgorithmicRoutingConfirmed] = useState(false);

  const { storedValue: isOnboardingClosedBefore, setValue: setOnboardingClosed } = useLocalStorage<boolean>(
    '__ONBOARDING_CLOSED_BEFORE__',
    false,
  );

  const { shiftId, date } = generateRidesData || {};

  const { data: unmappedEmployeesData, isLoading: isUnmappedEmployeesLoading } = useUnmappedEmployees({
    shiftId,
    date,
  });

  const { data: shiftDetailsData, isFetching: isShiftDetailsFetching } = useShiftDetails({
    shiftId,
  });

  const { query } = useRouter();
  const corporateId = (query.corporate as string) ?? '';
  const { data: corporateData } = useCorporate({ corporateId });

  const {
    mutate: generateRides,
    isLoading: isGenerateRidesLoading,
    isSuccess: isGenerateRidesSuccess,
  } = useGenerateRides({
    onSuccessCb: () => closeDrawer(),
  });

  const disableOnboarding = () => setOnboardingClosed(true);

  const hasUnmappedEmployees = unmappedEmployeesData?.total > 0;
  const hasOfficeLocation = !!shiftDetailsData?.officeLocation?.station;

  useEffect(() => {
    // reset user selections
    if (isDrawerOpen) {
      setAlgorithmicRoutingConfirmed(false);
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    if (isGenerateRidesSuccess) closeDrawer();
  }, [closeDrawer, isGenerateRidesSuccess]);

  const renderDrawerContent = () => {
    if (!isOnboardingClosedBefore) {
      return <Onboarding disableOnboarding={disableOnboarding} />;
    }

    if (isUnmappedEmployeesLoading || isShiftDetailsFetching) {
      return (
        <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <Spinner color="secondary" />
        </div>
      );
    }

    if (isDrawerOpen && (!hasOfficeLocation || hasUnmappedEmployees)) {
      return (
        <MissingDataStep
          unmappedEmployees={unmappedEmployeesData}
          generateRidesData={generateRidesData}
          closeDrawer={closeDrawer}
          hasUnmappedEmployees={hasUnmappedEmployees}
          hasOfficeLocation={hasOfficeLocation}
          officeLocationData={{
            ...shiftDetailsData.officeLocation,
            corporateId: shiftDetailsData.corporate,
          }}
        />
      );
    }

    if (corporateData?.config?.customRouting?.enabled && !algorithmicRoutingConfirmed) {
      return (
        <div>
          <header sx={{ px: 'spacing-m', flexShrink: 0, mb: 'spacing-m' }}>
            <h3 sx={{ display: 'flex', alignItems: 'center', p: 0, gap: 'spacing-xxs' }}>
              <GenerateRidesIcon /> Generate rides
              <span data-testId={'shiftTime'} sx={{ fontSize: '14px', mt: theme.space['spacing-xxs'] }}>
                | {generateRidesData?.shiftTime ?? '-'}
              </span>
            </h3>
          </header>
          <body sx={{ px: 'spacing-m' }}>
            <span sx={{ display: 'inline-block', variant: 'text.p-large', mb: 'spacing-xs', fontWeight: 700 }}>
              Select routing method
            </span>
            <RadioButtonGroup name="routing_method" value={routingMethod} onChange={setRoutingMethod}>
              <RadioButton
                title="Algorithmic Routing"
                description="Automated network generation process"
                value="algorithmic"
                sx={{
                  mb: 'spacing-s',
                }}
              />
              <RadioButton title="Custom Routing" description="Customized network generation process" value="custom" />
            </RadioButtonGroup>
          </body>
          <footer
            sx={{
              position: 'absolute',
              width: '100%',
              bottom: 0,
            }}
          >
            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                py: 'spacing-s',
                px: 'spacing-m',
                borderTop: '1px solid #ddd',
              }}
            >
              <Button
                type="submit"
                variant="secondary"
                disabled={isGenerateRidesLoading}
                onClick={() => {
                  if (routingMethod === 'algorithmic') {
                    setAlgorithmicRoutingConfirmed(true);
                  } else {
                    generateRides({
                      corporateId,
                      shift_id: shiftId,
                      routing_type: 'custom',
                      run_configs: {
                        date: new Date(query.date as string),
                        action_url: corporateData?.config?.customRouting?.actionUrl,
                        data_url: corporateData?.config?.customRouting?.dataUrl,
                      },
                    });
                  }
                }}
              >
                {routingMethod === 'custom' ? (
                  <span sx={{ display: 'flex', gap: 'spacing-xs' }}>
                    <GenerateRidesIcon />
                    Generate rides
                  </span>
                ) : (
                  'Next'
                )}
              </Button>
            </div>
          </footer>
        </div>
      );
    }

    return <GenerateRidesForm generateRidesData={generateRidesData} closeDrawer={closeDrawer} />;
  };

  return (
    <div>
      <Drawer closeDrawer={closeDrawer} isOpen={isDrawerOpen} size="medium" anchor={'right'}>
        {/* Close  */}
        <IconButton
          type="button"
          sx={{
            position: 'absolute',
            top: '0',
            insetInlineEnd: '0',
            zIndex: theme.zIndices['default'],
            bg: 'transparent',
            '&:hover, &:active': {
              bg: 'transparent',
            },
          }}
          onClick={() => closeDrawer()}
          icon={<CloseIcon />}
        />
        {renderDrawerContent()}
      </Drawer>
    </div>
  );
};
