import { useState, useEffect, useCallback } from 'react';

export const useToggleElement = (nodeElement: HTMLElement, dismissOnClickOutside = true): boolean => {
  const [toggled, setToggled] = useState(false);

  const handleClickOutside = useCallback(
    event => {
      if (dismissOnClickOutside && !nodeElement.contains(event.target)) {
        setToggled(false);
      }
    },
    [dismissOnClickOutside, nodeElement],
  );

  useEffect(() => {
    const handleClick = () => setToggled(!toggled);

    if (nodeElement) {
      nodeElement.addEventListener('click', handleClick);
      document.addEventListener('click', handleClickOutside);

      return () => {
        document.removeEventListener('click', handleClickOutside);
        nodeElement.removeEventListener('click', handleClick);
      };
    }

    return undefined;
  }, [nodeElement, toggled, handleClickOutside]);

  return toggled;
};
