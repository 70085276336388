import Image from 'next/image';
import format from 'date-fns/format';
import { useTheme } from '@swvl/theme';
import { Button } from '@swvl/button';
import { DownloadIcon, GenerateRidesIcon, CloseCircleIcon } from '@swvl/icons';
import unmappedEmployeesIcon from 'public/images/unmapped-employees-icon.png';
import { UnmappedEmployees, useBulkUploadEmployeesTemplate } from './resources';
import { DispatchStationForm } from './dispatch-station-form';
import { downloadCSV } from '@utils/download-csv';
import { DATE_FORMAT } from '@shared/constants';

export const MissingDataStep = ({
  unmappedEmployees,
  closeDrawer,
  hasUnmappedEmployees,
  hasOfficeLocation,
  generateRidesData,
  officeLocationData,
}: {
  unmappedEmployees: UnmappedEmployees;
  closeDrawer: () => void;
  hasUnmappedEmployees: boolean;
  hasOfficeLocation: boolean;
  generateRidesData: {
    shiftId: string;
    shiftTime: string;
    date: Date;
  };
  officeLocationData: {
    name: string;
    id: string;
    station?: string;
    corporateId: string;
  };
}) => {
  const { data: bulkUploadTemplate } = useBulkUploadEmployeesTemplate();
  const { theme } = useTheme();

  const handleDownloadEmployeesList = async () => {
    const output = unmappedEmployees?.hits?.map(({ user }) => {
      const employee: { [key: string]: string } = {};

      Object.values(bulkUploadTemplate).map(({ name, mandatory }) => {
        employee[name] = mandatory ? user : '';
      });

      return employee;
    });

    const { shiftId, shiftTime, date } = generateRidesData;
    const filename = `bulk-upload-unmapped-employees-${shiftId}-${shiftTime}-${format(new Date(date), DATE_FORMAT)}`
      .replace(/\s/g, '')
      .replace(/,/g, '');

    await downloadCSV(filename, output, false);

    // Close the drawer
    closeDrawer();
  };

  return (
    <div>
      <div sx={{ bg: theme.colors['bg-alert-ultra-light'], p: '28px' }}>
        <h3 sx={{ display: 'flex', alignItems: 'center', p: 0, m: 0, gap: 'spacing-xxs' }}>
          <GenerateRidesIcon /> Generate rides
        </h3>

        <div sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 'spacing-xxl' }}>
          <Image src={unmappedEmployeesIcon} alt="Unmapped Employees" />

          <h3
            sx={{ variant: 'text.h', m: '0', mt: 'spacing-m', mb: 'spacing-s', textAlign: 'center', maxWidth: '80%' }}
          >
            Missing data
          </h3>
        </div>
      </div>
      {hasUnmappedEmployees && (
        <div
          sx={{
            px: 'spacing-m',
            mt: 'spacing-l',
            display: 'flex',
          }}
        >
          <CloseCircleIcon sx={{ width: '24px', mr: 'spacing-m', color: theme.colors['negative-dark'] }} />
          <div sx={{ maxWidth: '85%' }}>
            <h4 sx={{ variant: 'text.p-large-bold', m: '0', mb: 'spacing-s', display: 'flex' }}>
              Station employee mapping is missing
            </h4>
            <p sx={{ variant: 'text.p-small-bold', m: '0', mb: 'spacing-s' }}>Step to assign stations to employees</p>
            <ol
              sx={{
                m: 0,
                p: 0,
                listStyleType: 'decimal-leading-zero',

                '& > li': {
                  pl: 'spacing-xs',
                  ml: 'spacing-m-l',
                  variant: 'text.p-small-medium',
                  '& + li': {
                    mt: 'spacing-xs',
                  },
                },
              }}
            >
              <li>Download employees list (stations unassigned)</li>
              <li>Update station information offline</li>
              <li>Upload employees list in the employees page</li>
            </ol>

            <div sx={{ mt: 'spacing-s', mb: 'spacing-l' }}>
              <Button
                variant="secondary"
                type="button"
                icon={<DownloadIcon />}
                iconPosition="start"
                onClick={() => handleDownloadEmployeesList()}
              >
                Download Employees List
              </Button>
            </div>
          </div>
        </div>
      )}
      {!hasOfficeLocation && (
        <div
          sx={{
            px: 'spacing-m',
            mt: 'spacing-l',
            display: 'flex',
          }}
        >
          <CloseCircleIcon
            sx={{ width: '24px', height: '24px', mr: 'spacing-m', color: theme.colors['negative-dark'] }}
          />
          <div sx={{ maxWidth: '85%' }}>
            <h4 sx={{ variant: 'text.p-large-bold', m: '0', mb: 'spacing-s' }}>
              Office location “{officeLocationData.name}” does not have a station mapped to it
            </h4>
            <DispatchStationForm
              corporateId={officeLocationData.corporateId}
              officeLocationId={officeLocationData.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};
