import {
  useState,
  PropsWithChildren,
  createContext,
  useContext,
  cloneElement,
  ReactElement,
  useEffect,
  CSSProperties,
  SetStateAction,
  Dispatch,
} from 'react';

import { createPortal } from 'react-dom';
import { ThemeUIStyleObject } from '@theme-ui/css';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';
import { useToggleElement } from '@hooks/use-toggle-element';

type PopoverProps = {
  placement?: Placement;
  containerStyles?: ThemeUIStyleObject;
  dismissOnClickOutside?: boolean;
  style?: CSSProperties;
};

type PopoverContextProps = {
  showContent?: boolean;
  popper?: Partial<ReturnType<typeof usePopper>>;
  containerStyles?: ThemeUIStyleObject;
  placement: Placement;
  setPopperReference?: Dispatch<SetStateAction<HTMLElement>>;
  setPopperElement?: Dispatch<SetStateAction<HTMLElement>>;
  style?: CSSProperties;
};

const PopoverContext = createContext<PopoverContextProps>({
  placement: 'top',
});

const usePopoverContext = () => {
  const context = useContext(PopoverContext);
  if (!context) {
    throw new Error('Components cannot be rendered outside the Popover component');
  }
  return context;
};

const Trigger = ({ children }: PropsWithChildren<unknown>) => {
  const { setPopperReference } = usePopoverContext();
  return cloneElement(children as ReactElement, { ref: setPopperReference });
};

const Content = ({ children }: PropsWithChildren<unknown>) => {
  const { setPopperElement, popper, showContent, containerStyles, style } = usePopoverContext();

  const { styles: popperStyles, attributes: popperAttributes } = popper;

  return createPortal(
    <div ref={setPopperElement} style={{ ...popperStyles.popper, ...style }} {...popperAttributes.popper}>
      {showContent && (
        <>
          <div sx={{ ...containerStyles }}>{children}</div>
        </>
      )}
    </div>,
    document.body,
  );
};

export const Popover = (props: PropsWithChildren<PopoverProps>) => {
  const { children, placement = 'bottom-end', containerStyles, dismissOnClickOutside = true, style } = props;
  const [popperReference, setPopperReference] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { update: updatePopper, ...popper } = usePopper(popperReference, popperElement, {
    placement,
    modifiers: [
      { name: 'arrow', options: { element: null } },
      {
        name: 'offset',
        options: {
          offset: [0, 0],
        },
      },
    ],
  });
  const [showContent, setShowContent] = useState(false);
  const isToggled = useToggleElement?.(popperReference, dismissOnClickOutside);

  useEffect(() => {
    setShowContent(isToggled);

    updatePopper?.();
  }, [isToggled, updatePopper]);

  return (
    <PopoverContext.Provider
      value={{
        showContent,
        popper,
        setPopperReference,
        setPopperElement,
        containerStyles,
        placement,
        style,
      }}
    >
      {children}
    </PopoverContext.Provider>
  );
};

Popover.Trigger = Trigger;
Popover.Content = Content;
