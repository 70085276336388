import { AxiosResponse } from 'axios';
import camelCase from 'camelcase-keys';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { showAlert } from '@swvl/alert';
import { getErrorMessage } from '@utils';
import { API } from '@utils/api-config';

// Types
type CorporateAPIResponse = {
  _id: string;
  sla: string;
  account_managers: AccountManager[];
  days_to_dispatch: number;
  allow_skip_station_with_bookings: boolean;
  shift_type: string;
  allow_delete_all_business_profiles: boolean;
  name: string;
  private_ride_enabled: boolean;
  city: City;
  displayName: string;
  invoice_email: string;
  createdAt: Date;
  updatedAt: Date;
  total_subscriptions: number;
  industry?: string;
  emails: string[];
  numbers: string[];
  hotlines: string[];
  shift_reservation_enabled: boolean;
  to_office_shift_deadline: { type: string; value: number };
  from_office_shift_deadline: { type: string; value: number };
  private_ride_auto_approve: boolean;
  slaConfig: {
    type: string;
    time: number;
  };
  config: {
    branding: {
      title: string;
      image_url: string;
    };
    custom_routing: {
      action_url: string;
      data_url: string;
      enabled: boolean;
    };
  };
};

type IndustryAPIResponse = {
  industries: string[];
};

type AccountManager = {
  _id: string;
  phone: string;
  name: string;
  type: string;
};

type City = {
  _id: string;
  name: string;
  currency: string;
  timezone: number;
};

export type Corporate = ReturnType<typeof corporateDataTransformer>;

// Transforms
const corporateDataTransformer = (data: CorporateAPIResponse) => camelCase(data, { deep: true });
const industryDataTransformer = (data: IndustryAPIResponse) => camelCase(data, { deep: true });

// Resources
const fetchCorporate = async ({ corporateId }: { corporateId: string }) => {
  const { data } = await API.get<CorporateAPIResponse>(`/corporate/${corporateId}`);

  return corporateDataTransformer(data);
};

const fetchIndustries = async () => {
  const { data } = await API.get<IndustryAPIResponse>(`corporate/industries`);
  return industryDataTransformer(data);
};

const updateCorporate = async ({
  corporateId,
  data,
}: {
  corporateId: string;
  data: Partial<{
    private_ride_enabled: boolean;
    invoice_email: string;
    numbers: string | string[];
    allow_skip_station_with_bookings: boolean;
    allow_delete_all_business_profiles: boolean;
    industry: string;
    private_ride_auto_approve: boolean;
    sla_config: {
      type: string;
      time: number;
    };
    config: {
      branding?: {
        title: string;
      };
      custom_routing?: {
        enabled: boolean;
        action_url: string;
      };
    };
  }>;
}) => {
  // workaround to change mobile numbers to array
  if (data?.numbers && typeof data.numbers === 'string') {
    data.numbers = [data.numbers];
  }

  const response = await API.patch(`/corporate/${corporateId}`, data);
  return response;
};

const updateReservationFlow = async ({
  corporateId,
  data,
}: {
  corporateId: string;
  data: {
    enabled: boolean;
    from_office_shift_deadline?: {
      type: string;
      value: number;
    };
    to_office_shift_deadline?: {
      type: string;
      value: number;
    };
  };
}) => {
  const response = await API.put(`/corporate/${corporateId}/reservation_flow`, data);
  return response;
};

// Hooks
export const useCorporate = ({ corporateId }: { corporateId: string }) => {
  return useQuery(['corporate/details', corporateId], () => fetchCorporate({ corporateId }), {
    enabled: !!corporateId,
  });
};

export const useReservationMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateReservationFlow, {
    mutationKey: 'office-location/station',
    onSuccess: () => {
      showAlert({
        message: `You have successfully update corporate.`,
        id: 'alert-success',
        variant: 'success',
        mode: 'dark',
      });
      queryClient.invalidateQueries('corporate/details');
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        id: 'alert-error',
        variant: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};

export const useIndustries = () => {
  return useQuery(['corporate/Industry'], () => fetchIndustries());
};

export const useCorporateMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(updateCorporate, {
    mutationKey: 'office-location/station',
    onSuccess: () => {
      showAlert({
        message: `You have successfully updated corporate.`,
        id: 'alert-success',
        variant: 'success',
        mode: 'dark',
      });

      queryClient.invalidateQueries('corporate/details');
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        message,
        id: 'alert-error',
        variant: 'error',
        mode: 'dark',
      });
    },
  });

  return mutation;
};
