import { useEffect } from 'react';
import { Field, ErrorMessage, FieldProps, useFormikContext } from 'formik';
import ReactDOMServer from 'react-dom/server';
import TagManager from 'react-gtm-module';
import { ButtonGroup, ButtonWrapper } from '@swvl/button-group';
import { InfoIcon } from '@swvl/icons';
import Input, { ErrorMessage as SwvlUIErrorMessage } from '@swvl/input';
import { useTheme } from '@swvl/theme';
import Toggle from '@swvl/toggle';
import { DIRECTION } from '@customers/reservations/constants';
import type { CorporateConfigs } from './resources';
import { Step } from './step';
import { StepProps, GenerateRidesFormValues, TypeOfRide } from './types';

export const BasicCorporateLevelStep = (props: StepProps & { initialCorporateConfigs?: CorporateConfigs }) => {
  const formikContext = useFormikContext<GenerateRidesFormValues>();
  const { theme } = useTheme();

  useEffect(() => {
    if (props.initialCorporateConfigs)
      formikContext.setValues({
        ...formikContext.values,
        ...props.initialCorporateConfigs,
        ...{ groupCorporateLocation: false, groupingRadius: 0, walkToStation: 0 },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialCorporateConfigs]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'screen_corporate_level_settings',
      },
    });
  }, []);

  return (
    <Step {...props}>
      <div>
        <Field name="stationWaitingTime">
          {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['stationWaitingTime']>) => (
            <Input
              {...field}
              type="number"
              id="stationWaitingTime"
              value={field?.value ?? ''}
              min={0}
              label={
                <span sx={{ display: 'flex', alignItems: 'center', gap: theme.space['spacing-xs'] }}>
                  Station waiting time (min)
                  <span
                    sx={{ display: 'flex', alignItems: 'center' }}
                    data-for="generateRidesTooltip"
                    data-tip={'Captain wait time at each station'}
                    data-iscapture="true"
                  >
                    <InfoIcon width={18} height={18} />
                  </span>
                </span>
              }
              success={touched && !error ? true : undefined}
              error={touched && error ? true : undefined}
            />
          )}
        </Field>
        <ErrorMessage name="stationWaitingTime" component={SwvlUIErrorMessage} />
      </div>

      <div>
        <Field name="absoluteExtraTime">
          {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['absoluteExtraTime']>) => (
            <Input
              {...field}
              value={field?.value ?? ''}
              type="number"
              id="absoluteExtraTime"
              min={0}
              label={
                <span sx={{ display: 'flex', alignItems: 'center', gap: theme.space['spacing-xs'] }}>
                  Absolute extra time (min)
                  <span
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    data-for="generateRidesTooltip"
                    data-html={true}
                    data-tip={ReactDOMServer.renderToStaticMarkup(
                      <div sx={{ width: '250px', textAlign: 'start' }}>
                        Acceptable additonal travel time for the employee compared to the time for a direct ride to
                        their destination
                      </div>,
                    )}
                    data-iscapture="true"
                  >
                    <InfoIcon width={18} height={18} />
                  </span>
                </span>
              }
              success={touched && !error ? true : undefined}
              error={touched && error ? true : undefined}
            />
          )}
        </Field>
        <ErrorMessage name="absoluteExtraTime" component={SwvlUIErrorMessage} />
      </div>

      <div sx={{ display: 'flex', gap: 'spacing-m' }}>
        <div>
          <Field name="maxRideDistance">
            {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['maxRideDistance']>) => {
              return (
                <Input
                  {...field}
                  value={field?.value ?? ''}
                  type="number"
                  id="maxRideDistance"
                  min={0}
                  label={<span>Max route distance (meters)</span>}
                  success={touched && !error ? true : undefined}
                  error={touched && error ? true : undefined}
                />
              );
            }}
          </Field>
          <ErrorMessage name="maxRideDistance" component={SwvlUIErrorMessage} />
        </div>

        <div>
          <Field name="maxRideDuration">
            {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['maxRideDuration']>) => (
              <Input
                {...field}
                value={field?.value ?? ''}
                type="number"
                id="maxRideDuration"
                min={0}
                label={<span>Max route duration (min)</span>}
                success={touched && !error ? true : undefined}
                error={touched && error ? true : undefined}
              />
            )}
          </Field>
          <ErrorMessage name="maxRideDuration" component={SwvlUIErrorMessage} />
        </div>
      </div>
      {props.generateRidesData?.direction && props.generateRidesData?.direction === DIRECTION.TO_OFFICE ? (
        <div>
          <Field name="officeArrivalBuffer">
            {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['officeArrivalBuffer']>) => (
              <Input
                {...field}
                value={field?.value ?? ''}
                type="number"
                id="officeArrivalBuffer"
                min={0}
                label={<span>Office Arrival Buffer (min)</span>}
                success={touched && !error ? true : undefined}
                error={touched && error ? true : undefined}
              />
            )}
          </Field>
          <ErrorMessage name="officeArrivalBuffer" component={SwvlUIErrorMessage} />
        </div>
      ) : null}
      <div>
        <label sx={{ display: 'block', mb: 'spacing-xs' }}>
          <span sx={{ display: 'flex', alignItems: 'center', gap: theme.space['spacing-xs'] }}>
            Employee Station Preference
            <span
              sx={{ display: 'flex', alignItems: 'center' }}
              data-for="generateRidesTooltip"
              data-html={true}
              data-tip={ReactDOMServer.renderToStaticMarkup(
                <div sx={{ width: '250px', textAlign: 'start' }}>
                  Home - Employees will be picked up from their home address. Station - Employees will be picked up from
                  a station location.
                </div>,
              )}
              data-iscapture="true"
            >
              <InfoIcon width={18} height={18} />
            </span>
          </span>
        </label>
        <ButtonGroup>
          <Field name="typeOfRide" value={TypeOfRide.DOOR_TO_DOOR}>
            {({ field, form }: FieldProps<GenerateRidesFormValues['typeOfRide']>) => (
              <ButtonWrapper
                title={TypeOfRide.DOOR_TO_DOOR}
                type="button"
                active={field.value === TypeOfRide.DOOR_TO_DOOR}
                onClick={() => form.setFieldValue(field.name, TypeOfRide.DOOR_TO_DOOR)}
                sx={{
                  width: '100%',
                  ...(field.value === TypeOfRide.DOOR_TO_DOOR && { backgroundColor: 'secondary-light-95' }),
                }}
              >
                Home
              </ButtonWrapper>
            )}
          </Field>
          <Field name="typeOfRide" value={TypeOfRide.STATION_PICKUP}>
            {({ field, form }: FieldProps<GenerateRidesFormValues['typeOfRide']>) => (
              <ButtonWrapper
                title={TypeOfRide.STATION_PICKUP}
                type="button"
                name="typeOfRide"
                active={field.value === TypeOfRide.STATION_PICKUP}
                value={TypeOfRide.STATION_PICKUP}
                onClick={() => form.setFieldValue(field.name, TypeOfRide.STATION_PICKUP)}
                sx={{
                  width: '100%',
                  ...(field.value === TypeOfRide.STATION_PICKUP && { backgroundColor: 'secondary-light-95' }),
                }}
              >
                Station
              </ButtonWrapper>
            )}
          </Field>
        </ButtonGroup>
      </div>

      {formikContext.values.typeOfRide === TypeOfRide.STATION_PICKUP ? (
        <div>
          <Field name="walkToStation">
            {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['walkToStation']>) => (
              <Input
                {...field}
                type="number"
                id="walkToStation"
                label={<span>Walk to station (meters)</span>}
                success={touched && !error ? true : undefined}
                error={touched && error ? true : undefined}
              />
            )}
          </Field>
          <ErrorMessage name="walkToStation" component={SwvlUIErrorMessage} />
        </div>
      ) : null}

      <div>
        <Field name="vehicleUtilization">
          {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['vehicleUtilization']>) => (
            <Input
              {...field}
              type="number"
              id="vehicleUtilization"
              min={0}
              max={100}
              required
              label={
                <span sx={{ display: 'flex', alignItems: 'center', gap: theme.space['spacing-xs'] }}>
                  Vehicle utilization (%)
                  <span
                    sx={{ display: 'flex', alignItems: 'center' }}
                    data-for="generateRidesTooltip"
                    data-html={true}
                    data-tip={ReactDOMServer.renderToStaticMarkup(
                      <div sx={{ width: '250px', textAlign: 'start' }}>
                        Maximum utilisation of each vehicle on the generated network
                      </div>,
                    )}
                    data-iscapture="true"
                  >
                    <InfoIcon width={18} height={18} />
                  </span>
                </span>
              }
              success={touched && !error ? true : undefined}
              error={touched && error ? true : undefined}
            />
          )}
        </Field>
        <ErrorMessage name="vehicleUtilization" component={SwvlUIErrorMessage} />
      </div>

      <div sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <label htmlFor="groupCorporateLocation">Would you like to group corporate locations?</label>
        <Field name="groupCorporateLocation">
          {({ field, form }: FieldProps<GenerateRidesFormValues['groupCorporateLocation']>) => (
            <Toggle
              id="groupCorporateLocation"
              checked={field.value}
              setChecked={value => form.setFieldValue(field.name, value)}
              disabled
            />
          )}
        </Field>
      </div>
      {formikContext.values.groupCorporateLocation ? (
        <div>
          <Field name="groupingRadius">
            {({ field, meta: { touched, error } }: FieldProps<GenerateRidesFormValues['groupingRadius']>) => (
              <Input
                {...field}
                value={field?.value ?? ''}
                type="number"
                id="groupingRadius"
                required
                label={<span>Group distance radius (meters)</span>}
                success={touched && !error ? true : undefined}
                error={touched && error ? true : undefined}
              />
            )}
          </Field>
          <ErrorMessage name="groupingRadius" component={SwvlUIErrorMessage} />
        </div>
      ) : null}

      <div sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <label htmlFor="costOptimization">Would you like to optimize for cost?</label>
        <Field name="costOptimization">
          {({ field, form }: FieldProps<GenerateRidesFormValues['costOptimization']>) => {
            return (
              <Toggle
                id="costOptimization"
                checked={field.value}
                setChecked={value => {
                  form.setFieldValue(field.name, value);
                }}
              />
            );
          }}
        </Field>
      </div>
    </Step>
  );
};
