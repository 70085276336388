import { saveAs } from 'file-saver';

/**
 * a function to download csv file
 * @param {string} fileName to be saved file name
 * @param {string[] | { [key: string]: string }} data the data to be saved
 * @param {boolean} isEmpty boolean to define if downloading prefilled or just an empty template
 *
 * @returns void
 */
export const downloadCSV = (fileName: string, data: string[] | { [key: string]: string }[], isEmpty: boolean) => {
  const newRowRegex = '\r\n';

  const columns = !isEmpty ? Object.keys(data[0]).join(',') + newRowRegex : data.join(',') + newRowRegex;

  const rows = !isEmpty
    ? (data as { [key: string]: string }[]).reduce((rows, row) => {
        return rows.concat(Object.values(row).join(',') + newRowRegex);
      }, '')
    : '';

  const parsedData = columns + rows;
  const csvFile = new File([parsedData], `${fileName}.csv`);
  saveAs(csvFile);
};
