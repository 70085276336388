import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import Tabs, { TabWrapper } from '@swvl/tabs';
import { IFileWithMeta } from '@swvl/file-upload';
import { StepProps, CorporateConfigsTypes, GenerateRidesFormValues } from './types';
import { Step } from './step';
import type { CorporateConfigs } from './resources';
import { BasicCorporateLevelStep } from '@customers/generate-rides/basic-corporate-level-step';
import AdvancedCorporateLevelStep from '@customers/generate-rides/advanced-corporate-level-step';
import { CORPORATE_ALGORITHMS } from '@customers/generate-rides/constants';

export const CorporateLevelStep = (
  props: StepProps & {
    initialCorporateConfigs?: CorporateConfigs;
    setCorpConfigsStep: (corpsStep: string) => void;
    corpConfigsStep: string;
    setFile: (file: IFileWithMeta) => void;
  },
) => {
  const { setFieldValue } = useFormikContext<GenerateRidesFormValues>();

  // Resetting the optimizer algo value to VRP once the user moves to the basic tab
  useEffect(() => {
    if (props.corpConfigsStep === CorporateConfigsTypes.BASIC) {
      setFieldValue('optimizer', CORPORATE_ALGORITHMS[0]);
    }
  }, [props.corpConfigsStep, setFieldValue]);
  return (
    <Step {...props} hideLabel>
      <div>
        <Tabs tabSize="large" activeKey={`${props.corpConfigsStep}`} onTabClick={props.setCorpConfigsStep}>
          <TabWrapper tab="Basic" key={CorporateConfigsTypes.BASIC}>
            <BasicCorporateLevelStep
              key="BasicCorporateLevelStep"
              label="Basic Corporate level settings"
              generateRidesData={props.generateRidesData}
              initialCorporateConfigs={props.initialCorporateConfigs}
            />
          </TabWrapper>
          <TabWrapper tab="Advanced" key={CorporateConfigsTypes.ADVANCED}>
            <AdvancedCorporateLevelStep
              key="AdvancedCorporateLevelStep"
              label="Advanced Corporate level settings"
              generateRidesData={props.generateRidesData}
              initialCorporateConfigs={props.initialCorporateConfigs}
              setFile={props.setFile}
            />
          </TabWrapper>
        </Tabs>
      </div>
    </Step>
  );
};
