import { DIRECTION } from '@customers/reservations/constants';
import { FormikConfig, FormikValues } from 'formik';

export enum GenerateRidesSteps {
  CORPORATE_LEVEL = '0',
  RUN_LEVEL = '1',
}
export enum CorporateConfigsTypes {
  BASIC = '0',
  ADVANCED = '1',
}
export enum TypeOfRide {
  DOOR_TO_DOOR = 'DoorToDoor',
  STATION_PICKUP = 'StationPickup',
}

export type Vehicle = {
  vehicleTypeId: string;
  noOfVehicles: number;
};

export type GenerateRidesFormValues = {
  stationWaitingTime?: number;
  absoluteExtraTime?: number;
  maxRideDistance?: number;
  maxRideDuration?: number;
  typeOfRide: TypeOfRide;
  walkToStation: number;
  vehicleUtilization: number;
  officeArrivalBuffer?: number;
  groupCorporateLocation: boolean;
  groupingRadius?: number;
  costOptimization: boolean;
  infiniteSeater: boolean;
  vehicles: Vehicle[];
  optimizer: { label: string; value: string };
};

export type StepProps = Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> & {
  label: string;
  generateRidesData: GenerateRidesData;
  hideLabel?: boolean;
};

export type GenerateRidesData =
  | {
      runId?: number;
      shiftId: string;
      shiftTime: string;
      date: Date;
      direction: DIRECTION;
    }
  | undefined;
