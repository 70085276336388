import { FC } from 'react';
import { Text } from 'theme-ui';

const FieldWrapper: FC<{ error?: string }> = ({ children, error }) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: 'column',
      mb: 'spacing-m',
    }}
  >
    {children}
    {error ? (
      <Text
        sx={{
          marginTop: '6px',
          color: 'crimson',
          fontSize: '12px',
        }}
      >
        {error}
      </Text>
    ) : null}
  </div>
);

export default FieldWrapper;
