import { Spinner, Text } from 'theme-ui';
import FileUpload, { IFileWithMeta } from '@swvl/file-upload';
import { DownloadIcon } from '@swvl/icons';
import { Step } from './step';
import { StepProps } from './types';
import { CorporateConfigs, downloadCSVTemp, useCorporateConfigsHeader } from './resources';
import { UPLOAD_STATUS } from './constants';

const AdvancedCorporateLevelStep = (
  props: StepProps & {
    initialCorporateConfigs?: CorporateConfigs;
    setFile: (file: IFileWithMeta) => void;
  },
) => {
  const { data: corpConfigHeaders, isLoading: isCorpConfigHeadersLoading } = useCorporateConfigsHeader();

  return (
    <Step {...props}>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'nowrap',
          height: `calc(100vh - 145px)`,
          position: 'relative',
        }}
      >
        <div
          sx={{
            px: 'spacing-m',
            paddingBottom: 'spacing-m',
            overflowY: 'auto',
            height: '90%',
          }}
        >
          <div>
            <div>
              <Text variant="p-large-bold" mb="spacing-xxs" sx={{ display: 'block', mb: 12, mt: 32 }}>
                Download template
              </Text>
              {!corpConfigHeaders?.fields && (
                <Text variant="p" mb="spacing-xxs" sx={{ display: 'block', mb: 12, mt: 32, textAlign: 'center' }}>
                  No Corporate Headers available
                </Text>
              )}
              {!isCorpConfigHeadersLoading && corpConfigHeaders?.fields && (
                <div
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    backgroundColor: '#F0F2F6',
                    width: '100%',
                    justifyContent: 'center',
                    height: 56,
                    borderRadius: 6,
                    mb: 32,
                  }}
                  data-testid="downloadConfig"
                  onClick={() =>
                    downloadCSVTemp({
                      values: props.initialCorporateConfigs,
                      labels: corpConfigHeaders?.fields,
                      isEmpty: !props.initialCorporateConfigs,
                    })
                  }
                >
                  <DownloadIcon sx={{ marginInlineEnd: 'spacing-xs' }} /> Download Corporate Config Template
                </div>
              )}
              {isCorpConfigHeadersLoading && (
                <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Spinner color="secondary" />
                </div>
              )}
            </div>
            <div>
              <Text variant="p-large-bold" mb="spacing-xxs" sx={{ display: 'block', mb: 12 }}>
                Upload
              </Text>
              <FileUpload
                onChangeStatus={(file, status) => {
                  if (status === UPLOAD_STATUS.DONE) {
                    props.setFile(file);
                  } else if (status === UPLOAD_STATUS.REMOVED) {
                    props.setFile(null);
                  }
                }}
                maxSize={1}
                accept=".csv,text/csv,application/vnd.ms-excel"
              />
            </div>
          </div>
        </div>
      </div>
    </Step>
  );
};

export default AdvancedCorporateLevelStep;
