import { useQuery } from 'react-query';
import { API } from '@utils/api-config';
import camelCase from 'camelcase-keys';
import { useCustomers } from '@context/customers';

// Types
type Gender = 'male' | 'female' | 'other';

type Employee = {
  _id: string;
  name: string;
  email: string;
  corporate: string;
  employee_id: string;
  gender: Gender;
  preferred_pickup: {
    address: string;
    coords: {
      lat: number;
      lng: number;
    };
    updatedAt: Date;
  };
  exclusion_reason?: string;
  active: boolean;
  createdAt: string;
  deleted: boolean;
  phone: string;
  phone_region_code: string;
  is_app_registered: boolean;
  user: string;
  updatedAt: string;
  pickup_station: {
    _id: string;
    name: string;
    name_ar: string;
    district: { name: string };
  };
  dropoff_station: {
    _id: string;
    name: string;
    name_ar: string;
    district: { name: string };
  };
  meeting_pickup_station: {
    _id: string;
    name: string;
    name_ar: string;
    district: { name: string };
  };
  meeting_dropoff_station: {
    _id: string;
    name: string;
    name_ar: string;
    district: { name: string };
  };
  group: { _id: string; name: string };
};

export type APIResponse = {
  type: string;
  hits: Employee[];
  total: number;
};

type QueryParams = {
  page?: number;
  limit?: number;
  date?: Date;
  corporateId?: string;
  shiftId?: string;
  runId?: string;
  isExcluded?: boolean;
};

export type Employees = ReturnType<typeof employeesDataTransformer>;

// Transforms
const employeesDataTransformer = (data: APIResponse) => camelCase(data, { deep: true });

// Resources
const fetchEmployees = async ({ shiftId, corporateId, runId, date, page, limit, isExcluded }: QueryParams) => {
  const params = {
    date,
    page,
    limit,
    ...(runId && isExcluded && { is_excluded: isExcluded }),
  };

  const url = runId
    ? `/corporate/${corporateId}/network-generator/shift-runs/${runId}/employees`
    : `/corporate/${corporateId}/shifts/${shiftId}/employees`;
  const { data } = await API.get<APIResponse>(url, {
    params,
  });

  return employeesDataTransformer(data);
};

// Hooks
export const useEmployees = ({ page = 1, limit = 10, shiftId, date, runId, isExcluded = false }: QueryParams) => {
  const { selectedCorporateId } = useCustomers();
  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['employees', shiftId, runId, [page, limit, date, isExcluded]],
    () => fetchEmployees({ shiftId, runId, page, limit, date, corporateId, isExcluded }),
    {
      enabled: (!!runId || !!shiftId) && !!corporateId,
    },
  );
};
