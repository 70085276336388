import { FC } from 'react';
import { StepProps } from './types';

export const Step: FC<StepProps> = ({ children, label, hideLabel = false }) => {
  return (
    <>
      {!hideLabel && <h2 sx={{ variant: 'text.p-large-bold' }}>{label}</h2>}

      <div
        sx={{
          '& > div + div': {
            mt: 'spacing-m',
          },
        }}
      >
        {children}
      </div>
    </>
  );
};
