import { useState, useMemo, SetStateAction, Dispatch, useEffect } from 'react';
import { Column } from 'react-table';
import { format } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import Table from '@swvl/table';
import { Refresh1Icon } from '@swvl/icons';
import { IconButton } from '@swvl/button';
import { useTheme } from '@swvl/theme';
import { useShiftRuns } from './resources';
import { EmptyState } from './empty-state';
import { ROUTING_STATUS, DIRECTION } from './constants';
import { convertMinutesAmPmTime, pluralize } from '@utils';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@shared/constants';
import { useApp } from '@context/app';
import { GenerateRidesData } from '@customers/generate-rides/types';

export const FailedShifts = ({
  selectedDate,
  selectedDirection,
  openDrawer,
  setGenerateRidesData,
}: {
  selectedDate: Date;
  selectedDirection?: DIRECTION;
  openDrawer: () => void;
  setGenerateRidesData: Dispatch<SetStateAction<GenerateRidesData | undefined>>;
}) => {
  const { sidebarWidth } = useApp();
  const { theme } = useTheme();
  const router = useRouter();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading } = useShiftRuns({
    page: currentPage + 1,
    limit: pageSize,
    date: selectedDate,
    direction: selectedDirection,
    status: 'FAILED',
  });

  useEffect(() => {
    if (!data || isLoading) return;
    TagManager.dataLayer({
      dataLayer: {
        event: 'employee_reservation_screen',
        shifts_counts: data.total,
      },
    });
  }, [data, isLoading]);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Shift time',
          accessor: 'time',
          Cell: ({ value }) => convertMinutesAmPmTime(value),
        },
        {
          Header: 'Shift direction',
          accessor: 'direction',
          Cell: ({ value }) => (value === 'to_office' ? 'To work' : 'From work'),
        },
        {
          Header: 'Employees',
          accessor: 'assignedEmployees',
          Cell: ({ value, row }) => (
            <Link
              passHref
              href={{
                pathname: `/customers/reservations/employees`,
                query: {
                  ...router.query,
                  shiftId: row.original.id,
                  shiftTime: convertMinutesAmPmTime(row.original.time),
                  runId: row.original.runId,
                },
              }}
            >
              <span
                sx={{ variant: 'text.link-text', cursor: 'pointer' }}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'action_employee_reservation_count',
                      shift_time: row.original.time,
                      shift_date: selectedDate,
                      source: ROUTING_STATUS.FAILED,
                      count: value,
                    },
                  })
                }
              >
                {value}
              </span>
            </Link>
          ),
        },
        {
          Header: 'Failed reason',
          accessor: 'failureReason',
          Cell: ({ value }) => (
            <span sx={{ whiteSpace: 'normal', marginTop: '10px', marginBottom: '10px', display: 'block' }}>
              {value}
            </span>
          ),
        },
        {
          Header: 'Failed at',
          accessor: 'failedAt',
          Cell: ({ value }) => format(new Date(value), DATE_TIME_FORMAT),
        },
        {
          Header: 'Action',
          accessor: 'actions',
          disableSortBy: true,
        },
      ] as Column<typeof tableData[number]>[],
    [router.query, selectedDate],
  );

  const tableData =
    data?.hits.map(it => {
      return {
        id: it.shiftId,
        runId: it.runId,
        time: it.shiftTime,
        direction: it.shiftDirection,
        assignedEmployees: it.assignedEmployees,
        failedAt: it.failedAt,
        failureReason: it.failureReason,
        actions: (
          <IconButton
            type="button"
            title="Rerun"
            icon={<Refresh1Icon />}
            onClick={() => {
              openDrawer();
              // Update the generate rides data
              setGenerateRidesData({
                runId: it.runId,
                shiftId: it.shiftId,
                shiftTime: convertMinutesAmPmTime(it.shiftTime),
                date: selectedDate,
                direction: it.shiftDirection,
              });
            }}
          />
        ),
      };
    }) || [];

  const paginationConfig = {
    totalCount: data?.total,
    initialPageIndex: currentPage,
    initialPageSize: pageSize,
    manualPagination: true,
    onPageChange: setCurrentPage,
    onPageSizeChange: setPageSize,
  };

  return (
    <div>
      <h2 sx={{ variant: 'text.p-large-bold', my: '21px' }}>
        {pluralize(data?.total ?? 0, 'Failed Shift')} ({format(selectedDate, DATE_FORMAT)})
      </h2>
      <div
        sx={{
          width: `calc(100vw - ${sidebarWidth} - ${theme.space['spacing-l']})`,
          transition: 'width 0.4s',
        }}
      >
        <Table
          data={tableData}
          columns={columns}
          fullWidth={true}
          sameSizeCells={false}
          paginationConfig={paginationConfig}
          enableSorting
          isLoading={isLoading}
          noDataComponent={<EmptyState activeTab={ROUTING_STATUS.FAILED} />}
          getRowId={row => row.id}
          getRowProps={(props, row) => ({
            ...props,
            'data-test-name': 'failed-shift-row',
            'data-test-id': row?.id,
          })}
          sortingConfig={{
            initialSortBy: [
              {
                id: 'time',
              },
            ],
          }}
          data-test-name="failed-shifts-table"
        />
      </div>
    </div>
  );
};
