import { useMutation, useQuery, useQueryClient } from 'react-query';
import camelCase from 'camelcase-keys';
import { saveAs } from 'file-saver';
import { AxiosResponse } from 'axios';
import { showAlert } from '@swvl/alert';
import { IFileWithMeta } from '@swvl/file-upload';
import { API } from '@utils/api-config';
import { useCustomers } from '@context/customers';
import { getErrorMessage } from '@utils';

// Types
type Subscription = {
  pricing: {
    ride_price: number;
  };
  subscription_type: string;
  deleted: boolean;
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  __v: number;
  bus_type: {
    _id: string;
    name: string;
    max_seats: number;
    seats: number;
  };
  contract: string;
  corporate: string;
};

type CorporateContractAPIResponse = {
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  corporate: {
    _id: string;
    city: {
      _id: string;
      currency: string;
    };
  };
  start_date: Date;
  expiry_date: Date;
  contract_type: string;
  version: number;
  deleted: boolean;
  included_holidays: number[];
  excluded_holidays: number[];
  __v: number;
  status: string;
  subscriptions: Subscription[];
  deduction_plan?: unknown;
};

enum TypeOfRide {
  DOOR_TO_DOOR = 'DoorToDoor',
  STATION_PICKUP = 'StationPickup',
}

type CorporateConfigsAPIResponse = {
  station_waiting_time?: number;
  absolute_extra_time?: number;
  max_ride_distance?: number;
  max_ride_duration?: number;
  office_arrival_buffer?: number;
  type_of_ride: TypeOfRide;
  walk_to_station: number;
  vehicle_utilization: number;
  group_corporate_location: boolean;
  grouping_radius?: number;
  cost_optimization: boolean;
};

type RunConfigs = {
  date: Date;
  action_url?: string;
  data_url?: string;
  infinite_seater?: 1;
  vehicles?: {
    no_of_vehicles: number;
    vehicle_type: string;
    vehicle_capacity: number;
    vehicle_type_id: string;
  }[];
  vehicles_capacity?: number;
  optimizer?: string;
};

export type GenerateRidesOptions = {
  shift_id: string;
  corporate_configs_id?: string;
  corporate_configs?: CorporateConfigsAPIResponse;
  run_configs: RunConfigs;
  routing_type?: 'custom' | 'algorithmic';
};

type UnmappedEmployeesAPIResponse = {
  type: string;
  hits: {
    _id: string;
    name: string;
    user: string;
  }[];
  total: number;
};
type CsvTemp = {
  values: CorporateConfigs;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labels: any[];
  isEmpty: boolean;
};

export type BulkUploadRequest = FormData;
export interface BulkUploadTemplateResponse {
  fields: string[];
  mandatory_fields: string[];
}
export type BulkUploadTemplateColumns = {
  name: string;
  mandatory: boolean;
}[];

export type BulkUploadModel = {
  columns: BulkUploadTemplateColumns;
};
type ShiftDetailsAPIResponse = {
  corporate: string;
  _id: string;
  office_location: {
    _id: string;
    name: string;
    station: string;
  };
};

type CsvTemplateAPIResponse = {
  mandatory_fields: string[];
  fields: string[];
  sample_data_exist: boolean;
};
export type CorporateContract = ReturnType<typeof corporateContractDataTransformer>;
export type CorporateConfigs = ReturnType<typeof corporateConfigDataTransformer>;
export type UnmappedEmployees = ReturnType<typeof unmappedEmployeesTransformer>;
export type ShiftDetails = ReturnType<typeof shiftDetailsTransformer>;

export const bulkUploadEmployeesTemplateTransformer = ({
  fields,
  mandatory_fields: mandatoryFields,
}: BulkUploadTemplateResponse): BulkUploadTemplateColumns => {
  const data = fields.map(field => ({
    name: `"${field}"`,
    mandatory: !!mandatoryFields?.includes(field),
  }));

  return data;
};

// Transforms
const corporateContractDataTransformer = (data: CorporateContractAPIResponse) => camelCase(data, { deep: true });

const corporateConfigDataTransformer = ({ data }: { data: CorporateConfigsAPIResponse }) =>
  camelCase(data, { deep: true });

const unmappedEmployeesTransformer = (data: UnmappedEmployeesAPIResponse) => camelCase(data, { deep: true });

const shiftDetailsTransformer = (data: ShiftDetailsAPIResponse) => camelCase(data, { deep: true });
const csvTemplateDataTransformer = (data: CsvTemplateAPIResponse) => camelCase(data, { deep: true });

// Resources
const fetchActiveCorporateContract = async ({ corporateId }: { corporateId: string }) => {
  const { data } = await API.get<CorporateContractAPIResponse>(`/corporate_contract/corporate/${corporateId}/active`);

  return corporateContractDataTransformer(data);
};

const generateRides = async (requestOptions: GenerateRidesOptions & { corporateId: string }) => {
  const { corporateId, ...rest } = requestOptions;
  const response = await API.post<CorporateContractAPIResponse>(`/corporate/${corporateId}/network-generator`, rest);

  return response;
};

export const fetchCorporateConfigHeaders = async ({ corporateId }: { corporateId: string }) => {
  const url = `/corporate/${corporateId}/network-generator/corporate-configs/upload/template`;
  const { data } = await API.get<CsvTemplateAPIResponse>(url);
  return csvTemplateDataTransformer(data);
};

const fetchCorporateConfig = async ({ corporateId, runId }: { corporateId: string; runId?: number }) => {
  const url = runId
    ? `/corporate/${corporateId}/network-generator/shift-runs/${runId}/configs`
    : `/corporate/${corporateId}/network-generator/configs`;

  const { data } = await API.get<{ data: CorporateConfigsAPIResponse }>(url);

  return corporateConfigDataTransformer(data);
};

const fetchUnmappedEmployees = async ({
  corporateId,
  shiftId,
  date,
}: {
  corporateId: string;
  shiftId: string;
  date: Date;
}) => {
  const params = {
    date,
  };
  const { data } = await API.get<UnmappedEmployeesAPIResponse>(
    `/corporate/${corporateId}/shifts/${shiftId}/unmapped-employees`,
    { params },
  );
  return unmappedEmployeesTransformer(data);
};

const fetchBulkUploadEmployeesTemplate = async () => {
  const { data } = await API.get<BulkUploadTemplateResponse>('/bulk_action/template/?action=update_business_profiles');

  return bulkUploadEmployeesTemplateTransformer(data);
};

const fetchShiftDetails = async ({ corporateId, shiftId }: { corporateId: string; shiftId: string }) => {
  const { data } = await API.get<ShiftDetailsAPIResponse>(`/corporate/${corporateId}/shift/${shiftId}`);
  return shiftDetailsTransformer(data);
};

const dispatchStation = async (station: { corporateId: string; station_id: string; officeLocationId: string }) => {
  const { corporateId, officeLocationId, ...rest } = station;
  const response = await API.patch(`/corporate/${corporateId}/office_locations/${officeLocationId}`, rest);
  return response;
};

const uploadCorpConfigsCsv = async ({ file, corporateId }: { corporateId: string; file: IFileWithMeta }) => {
  const formData = new FormData();
  formData.append('corporateConfig', file.file, 'corporateConfig.csv');
  const url = `corporate/${corporateId}/network-generator/corporate-configs/upload`;
  const response = await API.post<{ data: string }>(url, formData);
  return response.data;
};

// Hooks
export const useCorporateContract = () => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['corporate/active-contract', selectedCorporateId],
    () => fetchActiveCorporateContract({ corporateId }),
    {
      enabled: !!corporateId,
    },
  );
};

export const useGenerateRides = ({ onSuccessCb }: { onSuccessCb: () => void }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(generateRides, {
    mutationKey: 'rides/generate',
    onSuccess: () => {
      showAlert({
        id: 'generate-rides-error',
        message: 'You have successfully initiated ride generation. View the status in "In progress" tab.',
        type: 'success',
        mode: 'dark',
        autoClose: 5000,
      });
      queryClient.invalidateQueries('shifts');
      onSuccessCb();
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'generate-rides-error',
        message,
        type: 'error',
        mode: 'dark',
        autoClose: 5000,
      });
    },
  });

  return mutation;
};

export const useCorporateConfigsHeader = () => {
  const { selectedCorporateId } = useCustomers();
  const corporateId = selectedCorporateId ?? '';
  return useQuery(
    ['corporate/config-headers', selectedCorporateId],
    () => fetchCorporateConfigHeaders({ corporateId }),
    {
      enabled: !!corporateId,
      retry: false,
    },
  );
};
export const useCorporateConfigs = (params?: { runId?: number }) => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['corporate/config', selectedCorporateId],
    () => fetchCorporateConfig({ corporateId, runId: params?.runId }),
    {
      enabled: !!corporateId,
      retry: false,
    },
  );
};

export const useUnmappedEmployees = ({ shiftId, date }: { shiftId: string; date: Date }) => {
  const { selectedCorporateId } = useCustomers();

  const corporateId = selectedCorporateId ?? '';

  return useQuery(
    ['unmapped/employees', selectedCorporateId, shiftId],
    () => fetchUnmappedEmployees({ corporateId, shiftId, date }),
    {
      enabled: !!corporateId && !!shiftId,
    },
  );
};

export const useBulkUploadEmployeesTemplate = () => {
  return useQuery('employees/bulkupload/template', () => fetchBulkUploadEmployeesTemplate());
};

export const useShiftDetails = ({ shiftId }: { shiftId: string }) => {
  const { selectedCorporateId } = useCustomers();
  const corporateId = selectedCorporateId ?? '';

  return useQuery(['shift/details', selectedCorporateId, shiftId], () => fetchShiftDetails({ corporateId, shiftId }), {
    enabled: !!corporateId && !!shiftId,
  });
};

export const useDispatchStation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(dispatchStation, {
    mutationKey: 'office-location/station',
    onSuccess: () => {
      showAlert({
        id: 'office-location-station-success',
        message: 'You have successfully added the station.',
        type: 'success',
        mode: 'dark',
        autoClose: 5000,
      });
      queryClient.invalidateQueries('shift/details');
    },
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'office-location-station-error',
        message,
        type: 'error',
        mode: 'dark',
        autoClose: 5000,
      });
    },
  });

  return mutation;
};

export const useUploadCorpConfigsCsv = () => {
  const { selectedCorporateId } = useCustomers();
  const corporateId = selectedCorporateId ?? '';

  const mutation = useMutation((file: IFileWithMeta) => uploadCorpConfigsCsv({ file, corporateId }), {
    mutationKey: 'generate-rides/upload-corporate-configs',
    onError: (response: AxiosResponse) => {
      const message = getErrorMessage(response);
      showAlert({
        id: 'corporate-configs-upload-error',
        message,
        type: 'error',
        mode: 'dark',
        autoClose: 5000,
      });
    },
  });

  return mutation;
};

// utils
// Empty: if true, means that the data will provide only the fields(columns) else columns and rows
export const downloadCSVTemp = ({ values, labels, isEmpty }: CsvTemp) => {
  // To-Do: to be updated to reflect the two apis from BE
  const newRowRegex = '\r\n';
  const columns = !isEmpty ? labels.join(',') + newRowRegex : labels + newRowRegex;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rowsFields = {} as Record<string, any>;
  for (const label of labels) {
    rowsFields[label] = values[label as keyof CorporateConfigs];
  }
  const rows = !isEmpty
    ? Object.values(rowsFields)
        // Escaping commas
        .map(v => (typeof v === 'string' ? `"${v}"` : v))
        .join(',') + newRowRegex
    : '';

  const parsedData = `${columns}${rows}`;
  const csvFile = new File([parsedData], 'corporateConfig.csv');
  saveAs(csvFile);
};
