import { useEffect, useMemo } from 'react';
import { Field, ErrorMessage, FieldProps, FieldArray, useFormikContext } from 'formik';
import { Heading, Text } from 'theme-ui';
import TagManager from 'react-gtm-module';
import { IconButton } from '@swvl/button';
import Toggle from '@swvl/toggle';
import { InfoFilledIcon, InfoIcon, TrashIcon } from '@swvl/icons';
import Input, { ErrorMessage as SwvlUIErrorMessage } from '@swvl/input';
import { useTheme } from '@swvl/theme';
import { Select } from '@swvl/select';
import { Step } from './step';
import { StepProps, GenerateRidesFormValues, Vehicle } from './types';
import { CORPORATE_ALGORITHMS } from './constants';

export const RunLevelStep = (
  props: StepProps & {
    employeesCount: number;
    vehicleTypes: {
      [id: string]: {
        name: string;
        seats: number;
      };
    };
    isBasic: boolean;
  },
) => {
  const formikContext = useFormikContext<GenerateRidesFormValues>();
  const { theme } = useTheme();

  const selectOptions = useMemo(
    () =>
      Object.keys(props.vehicleTypes)
        .filter(
          vehicleTypeId =>
            !formikContext.values.vehicles.find(selectedVehicle => selectedVehicle.vehicleTypeId === vehicleTypeId),
        )
        .map(vehicleTypeId => ({
          label: props.vehicleTypes[vehicleTypeId].name,
          value: vehicleTypeId,
        })),
    [formikContext.values.vehicles, props.vehicleTypes],
  );

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'screen_run_level_settings',
        shift_date: props.generateRidesData?.date,
        shift_time: props.generateRidesData?.shiftTime,
        seats_required: props.employeesCount,
      },
    });
  }, [props.employeesCount, props.generateRidesData?.date, props.generateRidesData?.shiftTime]);

  return (
    <Step {...props}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.space['spacing-xs'],
          bg: theme.colors['info-light'],
          p: '12px 16px',
          borderRadius: '6px',
        }}
      >
        <InfoFilledIcon /> <span sx={{ variant: 'text.p-small' }}>{props.employeesCount} seats required</span>
      </div>
      <div>
        <Heading as="h5" variant="text.p-medium-bold" sx={{ mb: 'spacing-xs' }}>
          Algorithm
        </Heading>
        <Field name={`optimizer`}>
          {({ field, meta: { touched, error }, form }: FieldProps<GenerateRidesFormValues['optimizer']>) => (
            <Select
              {...field}
              name={field.name}
              isClearable={false}
              options={CORPORATE_ALGORITHMS}
              placeholder={'Vehicle Type'}
              id={`optimizer`}
              required
              error={touched && error ? error : undefined}
              onBlur={field.onBlur}
              onChange={option => {
                const selectedValue = option as { label: string; value: string };
                form.setFieldValue(field.name, selectedValue);
              }}
              isDisabled={props.isBasic}
            />
          )}
        </Field>
        <ErrorMessage name={`optimizer`} component={SwvlUIErrorMessage} />
      </div>
      <div>
        <Heading as="h5" variant="text.p-medium-bold" sx={{ mb: 'spacing-xs' }}>
          Partial recommendations
        </Heading>

        <div sx={{ width: '100%' }}>
          <Text variant="text.p-small-small" as="p" sx={{ mb: 'spacing-xs' }}>
            When enabled, the routing engine will return a partial output in the scenario that certain employees cannot
            be routed for.
          </Text>
          <Field name="infiniteSeater">
            {({ field, form }: FieldProps<GenerateRidesFormValues['infiniteSeater']>) => {
              return (
                <Toggle
                  id="infiniteSeater"
                  checked={field.value}
                  setChecked={value => form.setFieldValue(field.name, value)}
                  endText="Allow partial recommendations"
                  disabled={form.values.optimizer.value === CORPORATE_ALGORITHMS[1].value}
                />
              );
            }}
          </Field>
        </div>
      </div>
      <div>
        <Heading as="h5" variant="text.p-medium-bold" sx={{ mb: 'spacing-xs' }}>
          Add available fleet
        </Heading>

        <FieldArray
          name="vehicles"
          render={arrayHelpers => {
            const { vehicles } = formikContext.values;
            return (
              <>
                {vehicles.map((vehicle, idx) => (
                  <div
                    key={idx}
                    sx={{
                      display: 'grid',
                      gap: 'spacing-m',
                      gridTemplateColumns: '1.5fr 1fr 44px',
                      marginBottom: 'spacing-s',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      <Field name={`vehicles[${idx}].vehicleTypeId`}>
                        {({ field, meta: { touched, error }, form }: FieldProps<Vehicle['vehicleTypeId']>) => (
                          <Select
                            name={field.name}
                            isClearable={false}
                            options={selectOptions}
                            placeholder={'Vehicle Type'}
                            id={`vehicles[${idx}].vehicleTypeId`}
                            required
                            value={
                              vehicle.vehicleTypeId && {
                                label: props.vehicleTypes[vehicle.vehicleTypeId].name,
                                value: vehicle.vehicleTypeId,
                              }
                            }
                            label={idx === 0 ? 'Vehicle type' : ''}
                            error={touched && error ? error : undefined}
                            onBlur={field.onBlur}
                            onChange={option => {
                              const selectedValue = option as { label: string; value: string };
                              form.setFieldValue(field.name, selectedValue.value);
                            }}
                            sx={idx === 0 ? {} : { mt: '8px' }}
                          />
                        )}
                      </Field>
                      {vehicle.vehicleTypeId && (
                        <div
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 'spacing-xxs',
                            variant: 'text.p-x-small',
                            my: 'spacing-xxs',
                            color: 'info-dark',
                          }}
                        >
                          <InfoIcon width={14} height={14} />
                          <span>{props.vehicleTypes[vehicle.vehicleTypeId].seats} seats available</span>
                        </div>
                      )}
                      <ErrorMessage name={`vehicles[${idx}].vehicleType`} component={SwvlUIErrorMessage} />
                    </div>

                    <div>
                      <Field name={`vehicles[${idx}].noOfVehicles`}>
                        {({ field, meta: { touched, error } }: FieldProps<Vehicle['noOfVehicles']>) => (
                          <Input
                            {...field}
                            type="number"
                            id={`vehicles[${idx}].noOfVehicles`}
                            required
                            label={<span>{idx === 0 ? 'No. of vehicles' : ''}</span>}
                            success={touched && !error ? true : undefined}
                            error={touched && error ? true : undefined}
                            min={1}
                          />
                        )}
                      </Field>
                      <ErrorMessage name={`vehicles[${idx}].noOfVehicles`} component={SwvlUIErrorMessage} />
                    </div>
                    <div
                      sx={{
                        display: 'flex',
                        mt: idx === 0 ? 'spacing-m-l' : 'spacing-xs',
                      }}
                    >
                      {vehicles.length > 1 ? (
                        <IconButton
                          type="button"
                          onClick={() => arrayHelpers.remove(idx)}
                          icon={<TrashIcon width={24} height={24} sx={{ fill: theme.colors['negative-dark'] }} />}
                        />
                      ) : null}
                    </div>
                  </div>
                ))}
                {vehicles.length < Object.keys(props.vehicleTypes).length && (
                  <button
                    type="button"
                    sx={{
                      variant: 'text.link-text',
                      cursor: 'pointer',
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                    onClick={() => {
                      arrayHelpers.push({ vehicleType: '', noOfVehicles: 1 });

                      TagManager.dataLayer({
                        dataLayer: {
                          event: 'action_add_more_capacity',
                        },
                      });
                    }}
                  >
                    Add more...
                  </button>
                )}
              </>
            );
          }}
        />
      </div>
    </Step>
  );
};
