import { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import TabsWrapper, { TabWrapper } from '@swvl/tabs';
import { useTheme } from '@swvl/theme';
import { ButtonWrapper, ButtonGroup } from '@swvl/button-group';
import DatePicker, { DatePickerRef } from '@swvl/date-picker';
import { ArrowCircleLeftIcon, ArrowCircleRightIcon, CalendarIcon, RefreshIcon, SwapIcon } from '@swvl/icons';
import { Select } from '@swvl/select';
import { Button } from '@swvl/button';
import { ROUTING_STATUS, SELECTED_DATE_TAB, DIRECTION } from './constants';
import { GenerateRidesDrawer } from '@customers/generate-rides/drawer';
import { InprogressShifts } from './inprogress';
import { PendingShifts } from './pending';
import { FailedShifts } from './failed';
import { useReservations } from '@context/reservations';
import { CompletedReservations } from '@customers/reservations/completed';
import { GenerateRidesData } from '@customers/generate-rides/types';

export default function Listing() {
  const { theme } = useTheme();
  const queryClient = useQueryClient();

  // Drawer
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [generateRidesData, setGenerateRidesData] = useState<GenerateRidesData>();

  const { reservationsFilters, setReservationsFilters } = useReservations();

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => {
    setDrawerOpen(false);
    setGenerateRidesData(undefined);
  };

  /** Custom Date */
  const datePickerRef = useRef<DatePickerRef>();
  const [selectedTempDate, setSelectedTempDate] = useState(new Date());

  const onSelectDateTab = (tab: SELECTED_DATE_TAB) => {
    if (tab === SELECTED_DATE_TAB.TODAY) {
      setReservationsFilters({
        date: new Date(),
        dateTab: tab,
      });
    } else if (tab === SELECTED_DATE_TAB.TOMORROW) {
      setReservationsFilters({
        date: addDays(new Date(), 1),
        dateTab: tab,
      });
    } else {
      setReservationsFilters({
        date: selectedTempDate,
        dateTab: tab,
      });
    }
  };

  return (
    <div>
      <div
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: theme.space['spacing-s'] }}
      >
        <div sx={{ display: 'flex', gap: theme.space['spacing-s'], height: '40px' }}>
          <ButtonGroup>
            <ButtonWrapper
              title={SELECTED_DATE_TAB.TODAY}
              size="small"
              iconPosition="start"
              icon={<CalendarIcon width="20" height="20" />}
              onClick={() => onSelectDateTab(SELECTED_DATE_TAB.TODAY)}
              active={reservationsFilters?.dateTab === SELECTED_DATE_TAB.TODAY}
              data-test-name="shifts-filter-date-today"
              sx={{ height: '100%', px: '12px' }}
            >
              {SELECTED_DATE_TAB.TODAY}
            </ButtonWrapper>
            <ButtonWrapper
              title={SELECTED_DATE_TAB.TOMORROW}
              size="small"
              active={reservationsFilters?.dateTab === SELECTED_DATE_TAB.TOMORROW}
              onClick={() => onSelectDateTab(SELECTED_DATE_TAB.TOMORROW)}
              data-test-name="shifts-filter-date-tomorrow"
              sx={{ height: '100%', px: '12px' }}
            >
              {SELECTED_DATE_TAB.TOMORROW}
            </ButtonWrapper>
            <ButtonWrapper
              title="Custom"
              size="small"
              onClick={() => {
                datePickerRef?.current?.setOpen(true);
              }}
              sx={{
                position: 'initial',
                height: '100%',
                px: '12px',
              }}
              active={reservationsFilters?.dateTab === SELECTED_DATE_TAB.CUSTOM}
              data-test-name="shifts-filter-date-custom"
              data-test-value={new Date(reservationsFilters?.date || '')}
            >
              <DatePicker
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                ref={datePickerRef}
                selected={selectedTempDate}
                onChange={(date: Date, e) => {
                  e?.stopPropagation();
                  setSelectedTempDate(date);
                }}
                onClickOutside={e => {
                  e?.stopPropagation();
                  /** Reset temp date */
                  setSelectedTempDate(new Date(reservationsFilters?.date || ''));
                }}
                shouldCloseOnSelect={false}
                monthsShown={1}
                customInput={
                  <div>
                    {reservationsFilters?.dateTab === SELECTED_DATE_TAB.CUSTOM && reservationsFilters?.date
                      ? format(new Date(reservationsFilters?.date), 'dd MMM, yy')
                      : 'Custom'}
                  </div>
                }
              >
                <div sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <span
                    sx={{ variant: 'text.link-text', cursor: 'pointer', mr: 'spacing-s' }}
                    onClick={e => {
                      e.stopPropagation();
                      datePickerRef?.current?.setOpen(false);
                      /** Reset temp date */
                      setSelectedTempDate(new Date(reservationsFilters?.date || ''));
                    }}
                  >
                    Cancel
                  </span>
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={e => {
                      e.stopPropagation();
                      datePickerRef?.current?.setOpen(false);
                      onSelectDateTab(SELECTED_DATE_TAB.CUSTOM);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </DatePicker>
            </ButtonWrapper>
          </ButtonGroup>

          <Select
            id="select-direction"
            name="direction"
            isClearable={false}
            isSearchable={false}
            height="compact"
            options={[
              {
                value: DIRECTION.FROM_OFFICE,
                label: 'From work',
                startComponent: <ArrowCircleLeftIcon width={20} height={20} />,
              },
              {
                value: DIRECTION.TO_OFFICE,
                label: 'To work',
                startComponent: <ArrowCircleRightIcon width={20} height={20} />,
              },
              { value: undefined, label: 'Both', startComponent: <SwapIcon width={16} height={16} /> },
            ]}
            defaultValue={{ value: undefined, label: 'Both', startComponent: <SwapIcon width={16} height={16} /> }}
            onChange={value => {
              const selectedValue = value as { value: DIRECTION; label: string };
              const selected = selectedValue?.value ?? undefined;
              setReservationsFilters({
                direction: selected,
              });
            }}
            placeholder={'Select a direction'}
            sx={{
              minWidth: '176px',
            }}
            data-test-name="shifts-filter-direction"
            data-test-value={reservationsFilters?.direction}
          />
        </div>
        <Button
          type="button"
          variant="tertiary"
          icon={<RefreshIcon sx={{ marginInlineEnd: 'spacing-xs' }} width={20} height={20} />}
          onClick={() => queryClient.invalidateQueries('shifts')}
          sx={{ px: '16px' }}
        >
          Refresh shifts
        </Button>
      </div>

      <h5
        sx={{
          variant: 'text.p-x-small-bold',
          textTransform: 'uppercase',
          color: theme.colors['content-tertiary'],
          letterSpacing: '.75px',
          m: `${theme.space['spacing-m']} 0 ${theme.space['spacing-xxs']}`,
        }}
      >
        Routing Status
      </h5>
      <>
        <TabsWrapper
          tabSize="large"
          activeKey={reservationsFilters?.activeTab}
          onTabClick={tab => {
            setReservationsFilters({
              activeTab: tab as ROUTING_STATUS,
            });
          }}
        >
          <TabWrapper tab="Pending" key={ROUTING_STATUS.PENDING}>
            <PendingShifts
              selectedDate={new Date(reservationsFilters?.date || '')}
              selectedDirection={reservationsFilters?.direction}
              openDrawer={openDrawer}
              setGenerateRidesData={setGenerateRidesData}
            />
          </TabWrapper>
          <TabWrapper tab="Completed" key={ROUTING_STATUS.COMPLETED}>
            <CompletedReservations
              selectedDate={new Date(reservationsFilters?.date || '')}
              selectedDirection={reservationsFilters?.direction}
              openDrawer={openDrawer}
              setGenerateRidesData={setGenerateRidesData}
            />
          </TabWrapper>
          <TabWrapper tab="In progress" key={ROUTING_STATUS.IN_PROGRESS}>
            <InprogressShifts
              selectedDate={new Date(reservationsFilters?.date || '')}
              selectedDirection={reservationsFilters?.direction}
            />
          </TabWrapper>
          <TabWrapper tab="Failed" key={ROUTING_STATUS.FAILED}>
            <FailedShifts
              selectedDate={new Date(reservationsFilters?.date || '')}
              selectedDirection={reservationsFilters?.direction}
              openDrawer={openDrawer}
              setGenerateRidesData={setGenerateRidesData}
            />
          </TabWrapper>
        </TabsWrapper>
      </>

      <GenerateRidesDrawer
        isDrawerOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        generateRidesData={generateRidesData}
      />
    </div>
  );
}
