import { useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'theme-ui';
import TagManager from 'react-gtm-module';
import { useTheme } from '@swvl/theme';
import { Button } from '@swvl/button';
import { GenerateRidesIcon } from '@swvl/icons';
import Tabs, { TabWrapper } from '@swvl/tabs';
import Tooltip from '@swvl/tooltip';
import { IFileWithMeta } from '@swvl/file-upload';
import { showAlert } from '@swvl/alert';
import { useCustomers } from '@context/customers';
import { useEmployees } from '@customers/employees/resources';
import {
  GenerateRidesOptions,
  useCorporateConfigs,
  useCorporateContract,
  useGenerateRides,
  useUploadCorpConfigsCsv,
} from './resources';
import {
  GenerateRidesData,
  GenerateRidesSteps,
  GenerateRidesFormValues,
  TypeOfRide,
  Vehicle,
  CorporateConfigsTypes,
} from './types';
import { CORPORATE_ALGORITHMS } from './constants';
import { CorporateLevelStep } from './corporate-level-step';
import { RunLevelStep } from './run-level-step';

const vehiclesValidation = Yup.object({
  vehicles: Yup.array()
    .of(
      Yup.object().shape({
        vehicleTypeId: Yup.string().required('Required'),
        noOfVehicles: Yup.number().min(1, 'Must be greater than 0').required('Required'),
      }),
    )
    .required('Must have vehicles')
    .min(1, 'Minimum of 1 vehicle'),
});
const basicValidationSchema = [
  Yup.object({
    stationWaitingTime: Yup.number().required('Station waiting time is required').min(0, 'Must be greater than 0'),
    absoluteExtraTime: Yup.number().min(0, 'Must be greater than 0').nullable(true),
    maxRideDistance: Yup.number().min(0, 'Must be greater than 0').nullable(true),
    maxRideDuration: Yup.number().min(0, 'Must be greater than 0').nullable(true),
    vehicleUtilization: Yup.number()
      .required('Vehicle Utilization is required')
      .min(1, 'Must be greater than 0')
      .max(100, 'Must be less than 100'),
    walkToStation: Yup.number().min(0, 'Must be greater than 0'),
    officeArrivalBuffer: Yup.number().min(0, 'Must be greater than 0').nullable(true),
  }),
  vehiclesValidation,
];
const advancedValidationSchema = [Yup.object({}), vehiclesValidation];

export const GenerateRidesForm = ({
  generateRidesData,
  closeDrawer,
}: {
  generateRidesData: GenerateRidesData;
  closeDrawer: () => void;
}) => {
  const [activeStep, setActiveStep] = useState<string>(GenerateRidesSteps.CORPORATE_LEVEL);
  const [corpConfigsStep, setCorpConfigsStep] = useState<string>(CorporateConfigsTypes.BASIC);
  const [file, setFile] = useState<IFileWithMeta | null>(null);

  const { theme } = useTheme();

  const validationSchema = useMemo(() => {
    return corpConfigsStep === CorporateConfigsTypes.BASIC ? basicValidationSchema : advancedValidationSchema;
  }, [corpConfigsStep]);

  const { mutate: generateRides, isLoading: isGenerateRidesLoading } = useGenerateRides({
    onSuccessCb: () => closeDrawer(),
  });
  const { mutate: uploadCorpConfigsCsv, isLoading: isCsvUploading } = useUploadCorpConfigsCsv();
  const { selectedCorporateId } = useCustomers();
  const { data: activeContract } = useCorporateContract();
  const {
    data: initialCorporateConfigs,
    isLoading: isCorporateConfigsLoading,
    isError: isCorporateConfigsError,
  } = useCorporateConfigs({
    runId: generateRidesData?.runId,
  });

  /** Fetch the latest pending employees count */
  const { data: employeesData } = useEmployees({
    shiftId: generateRidesData?.shiftId,
    date: generateRidesData?.date,
    page: 1,
    limit: 1,
  });
  const employeesCount = useMemo(() => (employeesData ? employeesData.total : 0), [employeesData]);

  const vehicleTypes = useMemo(() => {
    if (activeContract)
      return activeContract.subscriptions?.reduce<{
        [id: string]: {
          name: string;
          seats: number;
        };
      }>(
        (acc, subscription) => ({
          ...acc,
          [subscription.busType.id]: subscription.busType,
        }),
        {},
      );
    return {};
  }, [activeContract]);

  const initialValues: GenerateRidesFormValues = useMemo(() => {
    return {
      ...(initialCorporateConfigs
        ? {
            ...initialCorporateConfigs,
            optimizer: CORPORATE_ALGORITHMS[0],
            costOptimization: initialCorporateConfigs.costOptimization ?? false,
          }
        : {
            stationWaitingTime: 0,
            absoluteExtraTime: 0,
            maxRideDistance: 0,
            maxRideDuration: 0,
            typeOfRide: TypeOfRide.DOOR_TO_DOOR,
            walkToStation: 0,
            vehicleUtilization: 0,
            officeArrivalBuffer: 0,
          }),
      groupCorporateLocation: false,
      groupingRadius: 0,
      costOptimization: false,
      infiniteSeater: false,
      vehicles: [
        {
          vehicleTypeId: '',
          noOfVehicles: 1,
        },
      ],
      optimizer: CORPORATE_ALGORITHMS[0],
    };
  }, [initialCorporateConfigs]);
  const isSubmittingForm = isGenerateRidesLoading || isCsvUploading;
  const handleSubmitRequest = async (values: GenerateRidesFormValues) => {
    if (corpConfigsStep === CorporateConfigsTypes.ADVANCED && !file) {
      showAlert({
        id: 'corporate-configs-upload-error',
        message: 'Please upload a file first or use the basic level Configurations',
        type: 'warning',
        mode: 'dark',
      });
      return;
    }
    const reqData: GenerateRidesOptions = {
      shift_id: generateRidesData?.shiftId as string,
      corporate_configs: {
        station_waiting_time: values.stationWaitingTime ? +values.stationWaitingTime : undefined,
        absolute_extra_time: values.absoluteExtraTime ? +values.absoluteExtraTime : undefined,
        max_ride_distance: values.maxRideDistance ? +values.maxRideDistance : undefined,
        max_ride_duration: values.maxRideDuration ? +values.maxRideDuration : undefined,
        office_arrival_buffer: values.officeArrivalBuffer ? +values.officeArrivalBuffer : undefined,
        type_of_ride: values.typeOfRide,
        vehicle_utilization: values.vehicleUtilization,
        group_corporate_location: values.groupCorporateLocation,
        grouping_radius: values.groupingRadius ? +values.groupingRadius : undefined,
        cost_optimization: values.costOptimization ? true : false,
        walk_to_station: values.walkToStation,
      },
      run_configs: {
        date: generateRidesData?.date as Date,
        infinite_seater: values.infiniteSeater ? 1 : undefined,
        vehicles: values.vehicles.map(selectedVehicle => {
          const vehicleType = vehicleTypes[selectedVehicle.vehicleTypeId];
          return {
            vehicle_type_id: selectedVehicle.vehicleTypeId,
            vehicle_type: vehicleType.name,
            vehicle_capacity: vehicleType.seats,
            no_of_vehicles: selectedVehicle.noOfVehicles,
          };
        }),
        vehicles_capacity: getSeatsCount(values.vehicles),
        optimizer: values.optimizer?.value,
      },
    };
    if (corpConfigsStep === CorporateConfigsTypes.ADVANCED && file) {
      uploadCorpConfigsCsv(file, {
        onSuccess(data) {
          delete reqData.corporate_configs;
          reqData.corporate_configs_id = data.data;
          reqData.run_configs.infinite_seater =
            reqData.run_configs.infinite_seater && values.optimizer.value === CORPORATE_ALGORITHMS[0].value
              ? 1
              : undefined;
          generateRides({ ...reqData, corporateId: selectedCorporateId as string, routing_type: 'algorithmic' });
          return;
        },
        onError() {
          return;
        },
      });
    } else {
      generateRides({ ...reqData, corporateId: selectedCorporateId as string, routing_type: 'algorithmic' });
    }
  };

  const isLastStep = +activeStep === Object.keys(GenerateRidesSteps).length - 1;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [activeStep]);

  const validateSeatsCount = (employeesCount: number, addedSeats: number) => {
    if (!employeesCount) {
      return <span>{addedSeats} Seats added</span>;
    }
    if (employeesCount <= addedSeats) {
      return (
        <span sx={{ variant: 'text.p-small', color: theme.colors['success-dark'] }}>{addedSeats} Seats added</span>
      );
    }

    return (
      <span sx={{ variant: 'text.p-small', color: theme.colors['negative-dark'] }}>
        {addedSeats} Seats added. At least {employeesCount - addedSeats} more seats required
      </span>
    );
  };

  const getSeatsCount = (vehicles: Vehicle[]) =>
    vehicles?.reduce((acc, item) => {
      const vehicleType = vehicleTypes[item?.vehicleTypeId];
      if (!vehicleType) return acc;
      return acc + vehicleType.seats * item.noOfVehicles;
    }, 0);

  if (isCorporateConfigsLoading && !isCorporateConfigsError) {
    return (
      <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        <Spinner color="secondary" />
      </div>
    );
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema[+activeStep]}
      onSubmit={async (values: GenerateRidesFormValues, actions: FormikHelpers<GenerateRidesFormValues>) => {
        if (isLastStep) {
          // Send API request
          await handleSubmitRequest(values);

          actions.setSubmitting(false);
        } else {
          setActiveStep(step => `${(+step as number) + 1}`);
          actions.setTouched({});
        }

        const dataLayer = isLastStep
          ? {
              event: 'action_run_generate_rides',
              distinct_vehicle_type: values.vehicles.map(vehicle => vehicle.vehicleTypeId).join(','),
              number_of_vehicles: values.vehicles.map(vehicle => vehicle.noOfVehicles).join(','),
              number_of_seats: getSeatsCount(values.vehicles),
              infinite_seater: values.infiniteSeater ? 1 : undefined,
            }
          : {
              event: 'action_next_corporate_level_settings',
              station_waiting_time: values.stationWaitingTime,
              absolute_extra_time: values.absoluteExtraTime,
              office_arrival_buffer: values.officeArrivalBuffer,
              max_route_distance: values.maxRideDistance,
              max_route_duration: values.maxRideDuration,
              employee_pickup_preference: values.typeOfRide,
              vehicle_utilization: values.vehicleUtilization,
              shift_date: generateRidesData?.date,
              shift_time: generateRidesData?.shiftTime,
            };

        TagManager.dataLayer({
          dataLayer,
        });
      }}
    >
      {formikForm => (
        <Form autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', height: '100%' }}>
          <header sx={{ px: 'spacing-m', flexShrink: 0 }}>
            <h3 sx={{ display: 'flex', alignItems: 'center', p: 0, gap: 'spacing-xxs' }}>
              <GenerateRidesIcon /> Generate rides
              <span data-testId={'shiftTime'} sx={{ fontSize: '14px', mt: theme.space['spacing-xxs'] }}>
                | {generateRidesData?.shiftTime ?? '-'}
              </span>
            </h3>
          </header>
          <div
            sx={{
              flexGrow: 1,
              overflowY: 'auto',
              px: 'spacing-m',
              paddingBottom: 'spacing-m',
              '.rc-tabs': { overflow: 'visible' },
              '.rc-tabs .rc-tabs-nav': { position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#fff' },
              '.rc-tabs-nav-operations': { display: 'none' },
            }}
          >
            <Tabs tabSize="large" activeKey={`${activeStep}`} onTabClick={setActiveStep}>
              <TabWrapper tab="Corporate level settings" key={GenerateRidesSteps.CORPORATE_LEVEL}>
                <CorporateLevelStep
                  key="CorporateLevelStep"
                  label="Corporate level settings"
                  generateRidesData={generateRidesData}
                  initialCorporateConfigs={initialCorporateConfigs}
                  setCorpConfigsStep={setCorpConfigsStep}
                  corpConfigsStep={corpConfigsStep}
                  setFile={setFile}
                />
              </TabWrapper>
              <TabWrapper tab="Run level settings" key={GenerateRidesSteps.RUN_LEVEL}>
                <RunLevelStep
                  key="RunLevelStep"
                  label="Run level settings"
                  generateRidesData={generateRidesData}
                  employeesCount={employeesCount}
                  vehicleTypes={vehicleTypes}
                  isBasic={corpConfigsStep === CorporateConfigsTypes.BASIC}
                />
              </TabWrapper>
            </Tabs>
          </div>
          <footer sx={{ flexShrink: 0 }}>
            {isLastStep ? (
              <p sx={{ variant: 'p-small-m', textAlign: 'center', color: theme.colors['negative-dark'] }}>
                {validateSeatsCount(employeesCount, getSeatsCount(formikForm.values.vehicles))}
              </p>
            ) : null}

            <div
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                py: 'spacing-s',
                px: 'spacing-m',
                borderTop: '1px solid #ddd',
              }}
            >
              <span>Step {(+activeStep as number) + 1} of 2</span>
              <Button type="submit" variant="secondary" disabled={isSubmittingForm}>
                {isLastStep ? (
                  isSubmittingForm ? (
                    <Spinner color="secondary" size="32" />
                  ) : (
                    <span sx={{ display: 'flex', gap: 'spacing-xs' }}>
                      <GenerateRidesIcon />
                      Generate rides
                    </span>
                  )
                ) : (
                  'Next'
                )}
              </Button>
            </div>
          </footer>
          <Tooltip id="generateRidesTooltip" place="right" type="dark" effect="solid" multiline />
        </Form>
      )}
    </Formik>
  );
};
