import { useState, useMemo, SetStateAction, Dispatch, useEffect, useCallback, MouseEvent } from 'react';
import { Column } from 'react-table';
import { format } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import Table from '@swvl/table';
import { useTheme } from '@swvl/theme';
import { useShiftRuns } from './resources';
import { EmptyState } from './empty-state';
import { ROUTING_STATUS, DIRECTION } from './constants';
import { convertMinutesAmPmTime, pluralize } from '@utils';
import { IconButton } from '@swvl/button';
import { EyeIcon, MoreVerticalIcon, Refresh1Icon } from '@swvl/icons';
import { DATE_FORMAT } from '@shared/constants';
import { useApp } from '@context/app';
import { Popover } from '@shared/popover';
import { GenerateRidesData } from '@customers/generate-rides/types';

export const popoverContainerStyles = {
  p: '8px',
  background: '#FFFFFF',
  boxShadow: '0px 3px 16px #DADADA, 0px 0px 1px #C7C7C7',
  borderRadius: '6px',
  minWidth: '170px',

  '& > div': {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    fontSize: '0.875rem',
    borderRadius: '6px',

    '&:hover': {
      background: '#f4f7ff',
    },

    '& > a': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      variant: 'text.p-small',
    },
  },
};

export const CompletedReservations = ({
  selectedDate,
  selectedDirection,
  openDrawer,
  setGenerateRidesData,
}: {
  selectedDate: Date;
  selectedDirection?: DIRECTION;
  openDrawer: () => void;
  setGenerateRidesData: Dispatch<SetStateAction<GenerateRidesData | undefined>>;
}) => {
  const router = useRouter();
  const { sidebarWidth } = useApp();
  const { theme } = useTheme();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading } = useShiftRuns({
    page: currentPage + 1,
    limit: pageSize,
    date: selectedDate,
    direction: selectedDirection,
    status: 'COMPLETED',
  });

  useEffect(() => {
    if (!data || isLoading) return;
    TagManager.dataLayer({
      dataLayer: {
        event: 'employee_reservation_screen',
        shifts_counts: data.total,
      },
    });
  }, [data, isLoading]);

  const viewAction = useCallback(
    row => {
      return (
        <div>
          <Link
            passHref
            href={{
              pathname: `/customers/reservations/recommended`,
              query: {
                ...router.query,
                shiftId: row.original.id,
                shiftTime: convertMinutesAmPmTime(row.original.time),
                runId: row.original.runId,
                shiftDirection: row.original.direction,
              },
            }}
          >
            <a
              onClick={() =>
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'action_view_reservation_details',
                    shiftId: row.original.id,
                    shiftTime: convertMinutesAmPmTime(row.original.time),
                    runId: row.original.runId,
                    source: 'Completed Reservations Listing ',
                  },
                })
              }
            >
              <EyeIcon />
              View Rides
            </a>
          </Link>
        </div>
      );
    },
    [router.query],
  );

  const reRunAction = useCallback(
    row => {
      return (
        <div>
          <a
            onClick={(evt: MouseEvent<HTMLAnchorElement>) => {
              evt.preventDefault();

              openDrawer();
              // Update the generate rides data
              setGenerateRidesData({
                runId: row.original.runId,
                shiftId: row.original.id,
                shiftTime: convertMinutesAmPmTime(row.original.time),
                date: selectedDate,
                direction: row.original.direction,
              });
              TagManager.dataLayer({
                dataLayer: {
                  event: 'action_reRun_reservation',
                  shift_time: row.original.time,
                  shift_date: selectedDate,
                  ride_id: row.original.id,
                  source: 'Completed Reservations Listing',
                },
              });
            }}
          >
            <>
              <Refresh1Icon /> Re-run
            </>
          </a>
        </div>
      );
    },
    [openDrawer, selectedDate, setGenerateRidesData],
  );
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Shift time',
          accessor: 'time',
          Cell: ({ value }) => convertMinutesAmPmTime(value),
        },
        {
          Header: 'Shift direction',
          accessor: 'direction',
          Cell: ({ value }) => (value === 'to_office' ? 'To work' : 'From work'),
        },
        {
          Header: 'Employees',
          accessor: 'assignedEmployees',
          Cell: ({ value, row }) => (
            <Link
              passHref
              href={{
                pathname: `/customers/reservations/employees`,
                query: {
                  ...router.query,
                  shiftId: row.original.id,
                  shiftTime: convertMinutesAmPmTime(row.original.time),
                  runId: row.original.runId,
                },
              }}
            >
              <span
                sx={{ variant: 'text.link-text', cursor: 'pointer' }}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'action_employee_reservation_count',
                      shift_time: row.original.time,
                      shift_date: selectedDate,
                      source: ROUTING_STATUS.COMPLETED,
                      count: value,
                    },
                  })
                }
              >
                {value}
              </span>
            </Link>
          ),
        },
        {
          Header: 'Excluded Employees',
          accessor: 'excludedEmployees',
          Cell: ({ value, row }) => (
            <Link
              passHref
              href={{
                pathname: `/customers/reservations/excluded-employees`,
                query: {
                  ...router.query,
                  shiftId: row.original.id,
                  shiftTime: convertMinutesAmPmTime(row.original.time),
                  runId: row.original.runId,
                },
              }}
            >
              <span
                sx={{ variant: 'text.link-text', cursor: 'pointer' }}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'action_click_excluded_employee',
                      source: 'recommendation_screen',
                    },
                  })
                }
              >
                {value}
              </span>
            </Link>
          ),
        },
        {
          Header: 'Recommended rides',
          accessor: 'recommendedRides',
          Cell: ({ value, row }) =>
            value ? (
              <Link
                passHref
                href={{
                  pathname: `/customers/reservations/recommended`,
                  query: {
                    ...router.query,
                    shiftId: row.original.id,
                    shiftTime: convertMinutesAmPmTime(row.original.time),
                    runId: row.original.runId,
                    shiftDirection: row.original.direction,
                  },
                }}
              >
                <span
                  sx={{ variant: 'text.link-text', cursor: 'pointer' }}
                  onClick={() =>
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'action_recommended_rides',
                        shift_time: row.original.time,
                        shift_date: selectedDate,
                        count: value,
                      },
                    })
                  }
                >
                  {value}
                </span>
              </Link>
            ) : (
              '-'
            ),
        },
        {
          Header: 'Dispatched rides',
          accessor: 'dispatchedRides',
          Cell: ({ value, row }) =>
            value ? (
              <Link
                passHref
                href={{
                  pathname: `/customers/reservations/recommended`,
                  query: {
                    ...router.query,
                    shiftId: row.original.id,
                    shiftTime: convertMinutesAmPmTime(row.original.time),
                    runId: row.original.runId,
                    status: 'DISPATCHED',
                  },
                }}
              >
                <span
                  sx={{ variant: 'text.link-text', cursor: 'pointer' }}
                  onClick={() =>
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'action_recommended_rides',
                        shift_time: row.original.time,
                        shift_date: selectedDate,
                        count: value,
                        status: 'DISPATCHED',
                      },
                    })
                  }
                >
                  {value}
                </span>
              </Link>
            ) : (
              '-'
            ),
        },
        {
          Header: 'Average utilization',
          accessor: 'averageUtilization',
          Cell: ({ value }) => (value ? `${Math.round(value * 100)}%` : '-'),
        },
        {
          Header: 'Action',
          accessor: 'actions',
          disableSortBy: true,
          Cell: ({ row }) => (
            <Popover containerStyles={popoverContainerStyles}>
              <Popover.Trigger>
                <span>
                  <IconButton type="button" data-test-name="popover-trigger" icon={<MoreVerticalIcon />} />
                </span>
              </Popover.Trigger>
              <Popover.Content>
                {/* View Action */}
                {viewAction(row)}

                {/* Re-run Action */}
                {reRunAction(row)}
              </Popover.Content>
            </Popover>
          ),
        },
      ] as Column<typeof tableData[number]>[],
    [router.query, selectedDate, viewAction, reRunAction],
  );

  const tableData =
    data?.hits.map(it => ({
      id: it.shiftId,
      runId: it.runId,
      time: it.shiftTime,
      direction: it.shiftDirection,
      assignedEmployees: it.assignedEmployees,
      excludedEmployees: it.excludedEmployees,
      recommendedRides: it.recommendedRides,
      dispatchedRides: it.dispatchedRides,
      averageUtilization: it.averageUtilization,
      actions: null,
    })) || [];

  const paginationConfig = {
    totalCount: data?.total,
    initialPageIndex: currentPage,
    initialPageSize: pageSize,
    manualPagination: true,
    onPageChange: setCurrentPage,
    onPageSizeChange: setPageSize,
  };

  return (
    <div>
      <h2 sx={{ variant: 'text.p-large-bold', my: '21px' }}>
        {pluralize(data?.total ?? 0, 'Completed Shift')} ({format(selectedDate, DATE_FORMAT)})
      </h2>
      <div
        sx={{
          width: `calc(100vw - ${sidebarWidth} - ${theme.space['spacing-l']})`,
          transition: 'width 0.4s',
        }}
      >
        <Table
          data={tableData}
          columns={columns}
          fullWidth={true}
          sameSizeCells={false}
          paginationConfig={paginationConfig}
          enableSorting
          isLoading={isLoading}
          noDataComponent={<EmptyState activeTab={ROUTING_STATUS.COMPLETED} />}
          getRowId={row => row.id}
          getRowProps={(props, row) => ({
            ...props,
            'data-test-name': 'completed-shift-row',
            'data-test-id': row?.id,
          })}
          sortingConfig={{
            initialSortBy: [
              {
                id: 'time',
              },
            ],
          }}
          data-test-name="completed-shifts-table"
        />
      </div>
    </div>
  );
};
