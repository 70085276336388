import { useState, useMemo, useEffect } from 'react';
import { Column } from 'react-table';
import { format } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Flex } from 'theme-ui';
import Table from '@swvl/table';
import { useTheme } from '@swvl/theme';
import { useShiftRuns, useSyncShiftRuns } from './resources';
import { EmptyState } from './empty-state';
import { ROUTING_STATUS, DIRECTION } from './constants';
import { convertMinutesAmPmTime, pluralize } from '@utils';
import TagManager from 'react-gtm-module';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@shared/constants';
import { useApp } from '@context/app';
import { Flip2Icon, MoreVerticalIcon } from '@swvl/icons';
import { Popover } from '@shared/popover';
import { IconButton } from '@swvl/button';
import { popoverContainerStyles } from '@customers/reservations/completed';

export const InprogressShifts = ({
  selectedDate,
  selectedDirection,
}: {
  selectedDate: Date;
  selectedDirection?: DIRECTION;
}) => {
  const router = useRouter();
  const { sidebarWidth } = useApp();
  const { theme } = useTheme();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { data, isLoading } = useShiftRuns({
    page: currentPage + 1,
    limit: pageSize,
    date: selectedDate,
    direction: selectedDirection,
    status: 'INPROGRESS',
  });

  const { mutate: syncShiftRun } = useSyncShiftRuns();

  useEffect(() => {
    if (!data || isLoading) return;
    TagManager.dataLayer({
      dataLayer: {
        event: 'employee_reservation_screen',
        shifts_counts: data.total,
      },
    });
  }, [data, isLoading]);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Shift time',
          accessor: 'time',
          Cell: ({ value }) => convertMinutesAmPmTime(value),
        },
        {
          Header: 'Shift direction',
          accessor: 'direction',
          Cell: ({ value }) => (value === 'to_office' ? 'To work' : 'From work'),
        },
        {
          Header: 'Employees',
          accessor: 'assignedEmployees',
          Cell: ({ value, row }) => (
            <Link
              passHref
              href={{
                pathname: `/customers/reservations/employees`,
                query: {
                  ...router.query,
                  shiftId: row.original.id,
                  shiftTime: convertMinutesAmPmTime(row.original.time),
                  runId: row.original.runId,
                },
              }}
            >
              <span
                sx={{ variant: 'text.link-text', cursor: 'pointer' }}
                onClick={() =>
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'action_employee_reservation_count',
                      shift_time: row.original.time,
                      shift_date: selectedDate,
                      source: ROUTING_STATUS.IN_PROGRESS,
                      count: value,
                    },
                  })
                }
              >
                {value}
              </span>
            </Link>
          ),
        },
        {
          Header: 'Generate rides initiated at',
          accessor: 'initiatedAt',
          Cell: ({ value }) => format(new Date(value), DATE_TIME_FORMAT),
        },
        {
          Header: 'Action',
          accessor: 'runId',
          disableSortBy: true,
          Cell: ({ value }) => (
            <Popover containerStyles={popoverContainerStyles}>
              <Popover.Trigger>
                <span>
                  <IconButton type="button" data-test-name="popover-trigger" icon={<MoreVerticalIcon />} />
                </span>
              </Popover.Trigger>
              <Popover.Content>
                <Flex
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={e => {
                    e.preventDefault();
                    syncShiftRun(value);
                  }}
                >
                  <IconButton type="button" icon={<Flip2Icon />} />
                  <span>Sync</span>
                </Flex>
              </Popover.Content>
            </Popover>
          ),
        },
      ] as Column<typeof tableData[number]>[],
    [router.query, selectedDate],
  );

  const tableData =
    data?.hits?.map(it => ({
      id: it.shiftId,
      runId: it.runId,
      time: it.shiftTime,
      direction: it.shiftDirection,
      assignedEmployees: it.assignedEmployees,
      initiatedAt: it.initiatedAt,
    })) || [];

  const paginationConfig = {
    totalCount: data?.total,
    initialPageIndex: currentPage,
    initialPageSize: pageSize,
    manualPagination: true,
    onPageChange: setCurrentPage,
    onPageSizeChange: setPageSize,
  };

  return (
    <div>
      <h2 sx={{ variant: 'text.p-large-bold', my: '21px' }}>
        {pluralize(data?.total ?? 0, 'In-progress Shift')} ({format(selectedDate, DATE_FORMAT)})
      </h2>
      <div
        sx={{
          width: `calc(100vw - ${sidebarWidth} - ${theme.space['spacing-l']})`,
          transition: 'width 0.4s',
        }}
      >
        <Table
          data={tableData}
          columns={columns}
          fullWidth={true}
          sameSizeCells={false}
          paginationConfig={paginationConfig}
          enableSorting
          isLoading={isLoading}
          noDataComponent={<EmptyState activeTab={ROUTING_STATUS.IN_PROGRESS} />}
          getRowId={row => row.id}
          getRowProps={(props, row) => ({
            ...props,
            'data-test-name': 'In-progress-shift-row',
            'data-test-id': row?.id,
          })}
          sortingConfig={{
            initialSortBy: [
              {
                id: 'time',
              },
            ],
          }}
          data-test-name="In-progress-shifts-table"
        />
      </div>
    </div>
  );
};
