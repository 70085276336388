import { useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import { Column } from 'react-table';
import { format, formatDuration, intervalToDuration, isAfter, subMinutes } from 'date-fns';
import Link from 'next/link';
import { useRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import Tooltip from '@swvl/tooltip';
import Table from '@swvl/table';
import { useTheme } from '@swvl/theme';
import { GenerateRidesIcon } from '@swvl/icons';
import { IconButton } from '@swvl/button';
import Tag from '@swvl/tag';
import { convertMinutesAmPmTime, pluralize } from '@utils';
import { usePendingShifts } from './resources';
import { EmptyState } from './empty-state';
import { ROUTING_STATUS, DIRECTION } from './constants';
import { DATE_FORMAT } from '@shared/constants';
import { useApp } from '@context/app';
import { GenerateRidesData } from '@customers/generate-rides/types';

export const PendingShifts = ({
  selectedDate,
  selectedDirection,
  openDrawer,
  setGenerateRidesData,
}: {
  selectedDate: Date;
  selectedDirection?: DIRECTION;
  openDrawer: () => void;
  setGenerateRidesData: Dispatch<SetStateAction<GenerateRidesData | undefined>>;
}) => {
  const router = useRouter();
  const { sidebarWidth } = useApp();
  const { theme } = useTheme();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading } = usePendingShifts({
    page: currentPage + 1,
    limit: pageSize,
    date: selectedDate,
    direction: selectedDirection,
  });

  useEffect(() => {
    if (!data || isLoading) return;
    TagManager.dataLayer({
      dataLayer: {
        event: 'employee_reservation_screen',
        shifts_counts: data.total,
      },
    });
  }, [data, isLoading]);

  const columns = useMemo(
    () =>
      [
        {
          Header: 'Shift time',
          accessor: 'time',
          Cell: ({ value }) => convertMinutesAmPmTime(value),
        },
        {
          Header: 'Time remaining till deadline (min)',
          accessor: 'deadline',
          Cell: ({ value }) => {
            // get current utc time
            const currDate = subMinutes(new Date(), new Date().getTimezoneOffset());
            const deadlineDate = new Date(value);

            /** If deadline has passed */
            if (isAfter(currDate, deadlineDate)) return <Tag variant="success-light">Ready for routing</Tag>;

            /** If deadline is upcoming */
            if (value)
              return (
                <Tag variant="background-neutral">
                  {formatDuration(
                    intervalToDuration({
                      start: currDate,
                      end: deadlineDate,
                    }),
                    { format: ['months', 'weeks', 'days', 'hours', 'minutes'] },
                  )}
                </Tag>
              );
            /** If no deadline available */
            return '-';
          },
        },
        {
          Header: 'Shift direction',
          accessor: 'direction',
          Cell: ({ value }) => (value === 'to_office' ? 'To work' : 'From work'),
        },
        {
          Header: () => (
            <div sx={{ justifyContent: 'flex-end', width: '100%', textAlign: 'end' }}>No. of employees reserved</div>
          ),
          accessor: 'assignedEmployees',
          Cell: ({ value, row }) => (
            <div sx={{ textAlign: 'end' }}>
              <Link
                passHref
                href={{
                  pathname: `/customers/reservations/employees`,
                  query: {
                    ...router.query,
                    shiftId: row.original.id,
                    shiftTime: convertMinutesAmPmTime(row.original.time),
                  },
                }}
              >
                <span
                  sx={{ variant: 'text.link-text', cursor: 'pointer', fontSize: '14px' }}
                  onClick={() =>
                    TagManager.dataLayer({
                      dataLayer: {
                        event: 'action_employee_reservation_count',
                        shift_time: row.original.time,
                        shift_date: selectedDate,
                        source: ROUTING_STATUS.PENDING,
                        count: value,
                      },
                    })
                  }
                >
                  {value}
                </span>
              </Link>
            </div>
          ),
        },
        {
          Header: 'Action',
          accessor: 'actions',
          disableSortBy: true,
        },
      ] as Column<typeof tableData[number]>[],
    [router.query, selectedDate],
  );

  const tableData =
    data?.hits?.map(it => ({
      id: it.id,
      time: it.time,
      deadline: it.deadlineDateTime,
      direction: it.direction,
      assignedEmployees: it.assignedEmployees,
      actions: (
        <>
          <IconButton
            type="button"
            icon={<GenerateRidesIcon />}
            data-for="GenerateRidesTooltip"
            data-tip="Generate Rides"
            data-iscapture="true"
            onClick={() => {
              openDrawer();
              // Update the generate rides data
              setGenerateRidesData({
                shiftId: it.id,
                shiftTime: convertMinutesAmPmTime(it.time),
                date: selectedDate,
                direction: it.direction,
              });

              TagManager.dataLayer({
                dataLayer: {
                  event: 'action_generate_rides',
                  shift_time: it.time,
                  shift_date: selectedDate,
                },
              });
            }}
          />
          <Tooltip id="GenerateRidesTooltip" place="right" type="dark" effect="solid" multiline />
        </>
      ),
    })) || [];

  const paginationConfig = {
    totalCount: data?.total,
    initialPageIndex: currentPage,
    initialPageSize: pageSize,
    manualPagination: true,
    onPageChange: setCurrentPage,
    onPageSizeChange: setPageSize,
  };

  return (
    <div>
      <h2 sx={{ variant: 'text.p-large-bold', my: '21px' }}>
        {pluralize(data?.total ?? 0, 'Pending Shift')} ({format(selectedDate, DATE_FORMAT)})
      </h2>
      <div
        sx={{
          width: `calc(100vw - ${sidebarWidth} - ${theme.space['spacing-l']})`,
          transition: 'width 0.4s',
        }}
      >
        <Table
          data={tableData}
          columns={columns}
          fullWidth={true}
          sameSizeCells={false}
          paginationConfig={paginationConfig}
          enableSorting
          isLoading={isLoading}
          noDataComponent={<EmptyState activeTab={ROUTING_STATUS.PENDING} />}
          getRowId={row => row.id}
          getRowProps={(props, row) => ({
            ...props,
            'data-test-name': 'pending-shift-row',
            'data-test-id': row?.id,
          })}
          sortingConfig={{
            initialSortBy: [
              {
                id: 'time',
              },
            ],
          }}
          data-test-name="pending-shifts-table"
        />
      </div>
    </div>
  );
};
