import Image from 'next/image';
import { useTheme } from '@swvl/theme';
import { ROUTING_STATUS } from './constants';

export const EmptyState = ({ activeTab }: { activeTab: ROUTING_STATUS }) => {
  const { theme } = useTheme();

  return (
    <div data-test-name="shifts-error-page" sx={{ width: '100%', textAlign: 'center', p: theme.space['spacing-l'] }}>
      <Image src={'/images/shifts-no-data.png'} alt="shifts" width={200} height={201} />
      <h3 sx={{ variant: 'text.h5', m: '30px 0 0' }}>There are no {activeTab} shifts</h3>
      <p sx={{ variant: 'text.p-medium', m: 'spacing-xs' }}>Try resetting the filters or using a different date</p>
    </div>
  );
};
