import { Formik, Form, FormikHelpers, Field, ErrorMessage, FieldProps } from 'formik';
import Input, { ErrorMessage as SwvlUIErrorMessage } from '@swvl/input';
import { object, string } from 'yup';
import { Spinner } from 'theme-ui';
import { Button } from '@swvl/button';
import { useDispatchStation } from './resources';
import FieldWrapper from '@shared/field-wrapper';

const validationSchema = object({
  station_id: string()
    .trim()
    .required('You must add station id')
    .test('No spaces allowed in station id', 'No spaces allowed in station ID', val => !val?.includes(' '))
    .matches(new RegExp('^[0-9a-fA-F]{24}'), 'Please enter a valid station ID'),
});

type DispatchStationFormProps = {
  corporateId: string;
  officeLocationId: string;
};
type OfficeLocation = {
  station_id: string;
};
const formInitialData: OfficeLocation = {
  station_id: '',
};

export const DispatchStationForm = ({ corporateId, officeLocationId }: DispatchStationFormProps) => {
  const { mutate: dispatchStation, isLoading: isDispatchStationLoading } = useDispatchStation();

  const handleOnSubmit = async (values: OfficeLocation, { setSubmitting }: FormikHelpers<OfficeLocation>) => {
    await dispatchStation({ ...values, corporateId, officeLocationId });
    setSubmitting(false);
  };

  return (
    <Formik initialValues={formInitialData} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
      {formikForm => (
        <Form>
          <div
            sx={{
              display: 'flex',
            }}
          >
            <Field name="station_id">
              {({ field }: FieldProps<OfficeLocation['station_id']>) => {
                return (
                  <div sx={{ width: '100%' }}>
                    <FieldWrapper>
                      <Input
                        {...field}
                        label={'Assign station ID'}
                        name="station_id"
                        placeholder="Enter station ID"
                        height="compact"
                      />
                      <ErrorMessage name="station_id" component={SwvlUIErrorMessage} />
                    </FieldWrapper>
                  </div>
                );
              }}
            </Field>

            <div sx={{ ml: 'spacing-xs' }}>
              <Button
                type="submit"
                variant="tertiary"
                disabled={isDispatchStationLoading || formikForm.isSubmitting}
                sx={{
                  mt: '30px',
                  minWidth: '105px',
                }}
                size="medium"
              >
                {isDispatchStationLoading ? (
                  <span sx={{ display: 'flex' }}>
                    <Spinner color="secondary" size="30" />
                  </span>
                ) : (
                  <span sx={{ display: 'flex', gap: 'spacing-xs' }}>Assign</span>
                )}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
